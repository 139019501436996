import { Role } from '@shared/models/types';
import { AnalyticsEvent, AnalyticsPage } from '@shared/services/analytics';
import {
  FirebaseAnalyticsEventProperties,
  WebFirebaseAnalyticsService
} from '@shared/web/services/WebFirebaseAnalyticsService';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { StudyoEnvironmentService } from './StudyoEnvironmentService';
import { StudyoAnalyticsEventCategory, StudyoAnalyticsPage, StudyoAnalyticsService } from './analytics';

interface StudyoBaseProperties {
  studyo_userId?: string;
  studyo_userEmail?: string;
  studyo_userName?: string;

  studyo_accountId?: string;
  studyo_accountRole?: Role | 'super-admin' | 'super-observer';
  studyo_configId?: string;
  studyo_schoolName?: string;
  studyo_accountFullName?: string;
}

type StudyoPageProperties = StudyoBaseProperties;

interface StudyoEventProperties extends StudyoBaseProperties, FirebaseAnalyticsEventProperties {
  studyo_activeScreen?: string;
}

interface StudyoErrorProperties extends StudyoBaseProperties {
  studyo_activeScreen?: string;
}

export class StudyoWebFirebaseAnalyticsService
  extends WebFirebaseAnalyticsService<
    AnalyticsPage<StudyoAnalyticsPage>,
    AnalyticsEvent<StudyoAnalyticsEventCategory>,
    StudyoPageProperties,
    StudyoEventProperties,
    StudyoErrorProperties
  >
  implements StudyoAnalyticsService
{
  private _activePage: AnalyticsPage<StudyoAnalyticsPage> | undefined;

  constructor(environmentService: StudyoEnvironmentService) {
    const app = initializeApp(environmentService.firebaseConfig);
    const analytics = getAnalytics(app);

    super(analytics);
  }

  trackPage(page: AnalyticsPage<StudyoAnalyticsPage>) {
    this._activePage = page;

    super.trackPage(page);
  }

  protected buildPageProperties(): StudyoPageProperties {
    return {
      ...this.buildBaseProperties()
    };
  }

  protected buildEventProperties(event: AnalyticsEvent<StudyoAnalyticsEventCategory>): StudyoEventProperties {
    const baseProperties = this.buildBaseProperties();

    return {
      ...baseProperties,
      eventCategory: event.action.category,
      eventLabel: event.label,
      studyo_activeScreen: this._activePage?.name ?? 'n/a'
    };
  }

  protected buildErrorProperties(): StudyoErrorProperties {
    const baseProperties = this.buildBaseProperties();

    return {
      ...baseProperties,
      studyo_activeScreen: this._activePage?.name ?? 'n/a'
    };
  }

  private buildBaseProperties(): StudyoBaseProperties {
    const properties: StudyoBaseProperties = {};

    if (this._userInfo != null) {
      properties.studyo_userId = this._userInfo.userId;
      properties.studyo_userEmail = this._userInfo.email ?? 'n/a';
      properties.studyo_userName = this._userInfo.userName ?? 'n/a';
    }

    if (this._configInfo != null) {
      properties.studyo_accountId = this._configInfo.accountId || 'none';
      properties.studyo_accountRole = this._configInfo.accountRole || 'individual';
      properties.studyo_configId = this._configInfo.configId || 'none';
      properties.studyo_schoolName = this._configInfo.schoolName || 'none';
      properties.studyo_accountFullName = this._configInfo.accountFullName || 'none';
    }

    return properties;
  }
}
