import { ContentDefinitionUtils, DateUtils } from '@shared/components/utils';
import { AdminOrRootAuthorizationRoles } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { AccountService, StudyoSettingsStore } from '../../services';

export interface BaseAgendaViewModel {
  readonly lateTasksCount: number;
  readonly isPreparing: boolean;
}

export class AppBaseAgendaViewModel implements BaseAgendaViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _settingsStore: StudyoSettingsStore,
    private readonly _localizationService: LocalizationService
  ) {
    makeObservable(this);
  }

  @computed
  get lateTasksCount(): number {
    const lateTasks = _.filter(
      this.contents,
      (c) => ContentDefinitionUtils.isVisibleContent(c) && c.state == 'active' && DateUtils.isLate(c.dueDay)
    );

    return lateTasks.length;
  }

  @computed
  get isPreparing() {
    if (this._accountService.currentDisplayedConfiguration?.state === 'preparing') {
      // We allow viewing a preparing planner if the user is impersonating and admin or root.
      return (
        !this._accountService.displayedAccountData.isImpersonating ||
        !this._accountService.isAllowed(AdminOrRootAuthorizationRoles)
      );
    }

    return false;
  }

  @computed
  private get data() {
    return this._accountService.displayedAccountData;
  }

  @computed
  private get contents() {
    if (!this.data.hasData) {
      return [];
    }

    const filterManager = this._settingsStore.getPreferences(this.data.accountId).timelineContentFilters;
    const tasks = this.data.contents.filter((cd) => cd.kind === 'task');

    return filterManager.applyFiltersToContents(tasks, this._localizationService);
  }
}
