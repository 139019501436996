import { AppDataLoader, Storage } from '@shared/services';
import { Dictionary } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppContentFiltersManager, ContentFiltersManager } from '../../utils';
import * as Types from './SettingTypes';
import { StudyoSettingsKeys } from './SettingsKeys';
import { StudyoAccountSettings } from './StudyoAccountSettings';

export class AppStudyoAccountSettings extends AppDataLoader implements StudyoAccountSettings {
  /* Public fields */

  readonly accountId: string;

  /* Private fields */

  //
  // Generic
  //

  private readonly _defaultAnnouncementDateToday: boolean = true;
  @observable private _announcementDateToday = this._defaultAnnouncementDateToday;

  private readonly _defaultNewTaskDueNextPeriod: boolean = false;
  @observable private _newTaskDueNextPeriod = this._defaultNewTaskDueNextPeriod;

  //
  // Daily
  //

  private readonly _defaultDailyPeriodHeight: Types.DailyPeriodHeightKind = 'medium';
  @observable private _dailyPeriodHeight = this._defaultDailyPeriodHeight;

  private readonly _defaultDailyContentDisplayKind: Types.DailyContentDisplayKind = 'both';
  @observable private _dailyContentDisplayKind = this._defaultDailyContentDisplayKind;

  private readonly _defaultDailyContentPresentationKind: Types.DailyContentPresentationKind = 'icon-title';
  @observable private _dailyContentPresentationKind = this._defaultDailyContentPresentationKind;

  private readonly _defaultDailyShowAssignmentDay: boolean = true;
  @observable private _dailyShowAssignmentDay = this._defaultDailyShowAssignmentDay;

  //
  // Weekly
  //

  private readonly _defaultWeeklyPeriodHeight: Types.WeeklyPeriodHeightKind = 'medium';
  @observable private _weeklyPeriodHeight = this._defaultWeeklyPeriodHeight;

  private readonly _defaultWeeklyContentDisplayKind: Types.WeeklyContentDisplayKind = 'tasks';
  @observable private _weeklyContentDisplayKind = this._defaultWeeklyContentDisplayKind;

  private readonly _defaultWeeklyDisplayWeekends: boolean = true;
  @observable private _weeklyDisplayWeekends = this._defaultWeeklyDisplayWeekends;

  //
  // Monthly
  //

  private readonly _defaultMonthlySectionFilters: string[] = [];
  @observable private _monthlySectionFilters = this._defaultMonthlySectionFilters;

  // The notes are always hidden in the monthly view
  private readonly _monthlyShowNotes = false;

  private readonly _defaultMonthlyShowTasks: boolean = true;
  @observable private _monthlyShowTasks = this._defaultMonthlyShowTasks;

  @observable private _monthlyContentFilters = new AppContentFiltersManager();

  //
  // Timeline
  //

  @observable private _timelineContentFilters = new AppContentFiltersManager();

  private readonly _defaultTimelineCellSize: Types.TimelineCellSizeKind = 'regular';
  @observable private _timelineCelSize = this._defaultTimelineCellSize;

  private readonly _defaultTimelineShowAssignmentDay: boolean = false;
  @observable private _timelineShowAssignmentDay = this._defaultTimelineShowAssignmentDay;

  private readonly _defaultTimelineLimitWeeks: boolean = true;
  @observable private _timelineLimitWeeks = this._defaultTimelineLimitWeeks;

  //
  // Planner
  //

  private readonly _defaultPlannerPeriodHeight: Types.PlannerPeriodHeightKind = 'small';
  @observable private _plannerPeriodHeight = this._defaultPlannerPeriodHeight;

  private readonly _defaultPlannerSectionFilters: string[] = [];
  @observable private _plannerSectionFilters = this._defaultPlannerSectionFilters;

  private readonly _defaultPlannerDisplayWeekends: boolean = true;
  @observable private _plannerDisplayWeekends = this._defaultPlannerDisplayWeekends;

  private readonly _defaultPlannerShowAssignmentDay: boolean = true;
  @observable private _plannerShowAssignmentDay = this._defaultPlannerShowAssignmentDay;

  @observable private _plannerContentFilters = new AppContentFiltersManager();

  //
  // Periods
  //

  private readonly _defaultPeriodSectionFilters: string[] = [];
  @observable private _periodSectionFilters = this._defaultPeriodSectionFilters;

  private readonly _defaultPeriodShowAssignmentDay: boolean = true;
  @observable private _periodShowAssignmentDay = this._defaultPeriodShowAssignmentDay;

  @observable private _periodContentFilters = new AppContentFiltersManager();

  //
  // UI
  //

  private readonly _defaultBackgroundOpacity = 0.75;
  @observable private _backgroundOpacity = this._defaultBackgroundOpacity;

  private readonly _defaultBackgroundOnlyOnHeader: boolean = false;
  @observable private _backgroundOnlyOnHeader = this._defaultBackgroundOnlyOnHeader;

  constructor(
    private readonly _localStorageResolver: () => Storage,
    accountId: string
  ) {
    super();
    makeObservable(this);
    this.accountId = accountId;
    void this.loadData();
  }

  /* Public properties */

  //
  // Generic
  //

  @computed
  get announcementDateToday(): boolean {
    return this._announcementDateToday;
  }

  set announcementDateToday(value: boolean) {
    this._announcementDateToday = value;
    void this.saveValue(StudyoSettingsKeys.announcementDateToday, value);
  }

  @computed
  get newTaskDueNextPeriod(): boolean {
    return this._newTaskDueNextPeriod;
  }

  set newTaskDueNextPeriod(value: boolean) {
    this._newTaskDueNextPeriod = value;
    void this.saveValue(StudyoSettingsKeys.newTaskDueNextPeriod, value);
  }

  //
  // Daily
  //

  @computed
  get dailyPeriodHeight(): Types.DailyPeriodHeightKind {
    return this._dailyPeriodHeight;
  }

  set dailyPeriodHeight(value: Types.DailyPeriodHeightKind) {
    this._dailyPeriodHeight = value;
    void this.saveValue(StudyoSettingsKeys.dailyPeriodHeight, value);
  }

  @computed
  get dailyContentDisplayKind(): Types.DailyContentDisplayKind {
    return this._dailyContentDisplayKind;
  }

  set dailyContentDisplayKind(value: Types.DailyContentDisplayKind) {
    this._dailyContentDisplayKind = value;
    void this.saveValue(StudyoSettingsKeys.dailyContentDisplayKind, value);
  }

  @computed
  get dailyContentPresentationKind(): Types.DailyContentPresentationKind {
    return this._dailyContentPresentationKind;
  }

  set dailyContentPresentationKind(value: Types.DailyContentPresentationKind) {
    this._dailyContentPresentationKind = value;
    void this.saveValue(StudyoSettingsKeys.dailyContentPresentationKind, value);
  }

  @computed
  get dailyShowAssignmentDay(): boolean {
    return this._dailyShowAssignmentDay;
  }

  set dailyShowAssignmentDay(value: boolean) {
    this._dailyShowAssignmentDay = value;
    void this.saveValue(StudyoSettingsKeys.dailyShowAssignmentDay, value);
  }

  //
  // Weekly
  //

  @computed
  get weeklyPeriodHeight(): Types.WeeklyPeriodHeightKind {
    return this._weeklyPeriodHeight;
  }

  set weeklyPeriodHeight(value: Types.WeeklyPeriodHeightKind) {
    this._weeklyPeriodHeight = value;
    void this.saveValue(StudyoSettingsKeys.weeklyPeriodHeight, value);
  }

  @computed
  get weeklyContentDisplayKind(): Types.WeeklyContentDisplayKind {
    return this._weeklyContentDisplayKind;
  }

  set weeklyContentDisplayKind(value: Types.WeeklyContentDisplayKind) {
    this._weeklyContentDisplayKind = value;
    void this.saveValue(StudyoSettingsKeys.weeklyContentDisplayKind, value);
  }

  @computed
  get weeklyDisplayWeekends(): boolean {
    return this._weeklyDisplayWeekends;
  }

  set weeklyDisplayWeekends(value: boolean) {
    this._weeklyDisplayWeekends = value;
    void this.saveValue(StudyoSettingsKeys.weeklyDisplayWeekends, value);
  }

  //
  // Monthly
  //

  @computed
  get monthlySectionFilters(): string[] {
    // We return a slice of the observable array to prevent having a reference.
    return this._monthlySectionFilters.slice();
  }

  set monthlySectionFilters(value: string[]) {
    // We want to present setting a reference to an external observable array
    // by doing a slice on the passed array.
    this._monthlySectionFilters = value.slice();
    console.warn(value);
    void this.saveValueForAccount(StudyoSettingsKeys.monthlySectionFilters, this._monthlySectionFilters);
  }

  @computed
  get monthlyContentFilters(): ContentFiltersManager {
    return this._monthlyContentFilters;
  }

  saveMonthlyContentFilters() {
    void this.saveValueForAccount(StudyoSettingsKeys.monthlyContentFilters, this._monthlyContentFilters.jsonValue);
  }

  @computed
  get monthlyShowNotes(): boolean {
    return this._monthlyShowNotes;
  }

  @computed
  get monthlyShowTasks(): boolean {
    return this._monthlyShowTasks;
  }

  set monthlyShowTasks(value: boolean) {
    this._monthlyShowTasks = value;
    void this.saveValueForAccount(StudyoSettingsKeys.monthlyShowTasks, this._monthlyShowTasks);
  }
  //
  // Timeline
  //

  get timelineContentFilters(): ContentFiltersManager {
    return this._timelineContentFilters;
  }

  saveTimelineContentFilters() {
    void this.saveValueForAccount(StudyoSettingsKeys.timelineContentFilters, this._timelineContentFilters.jsonValue);
  }

  @computed
  get timelineCellSize(): Types.TimelineCellSizeKind {
    return this._timelineCelSize;
  }

  set timelineCellSize(value: Types.TimelineCellSizeKind) {
    this._timelineCelSize = value;
    void this.saveValue(StudyoSettingsKeys.timelineCellSize, value);
  }

  @computed
  get timelineShowAssignmentDay(): boolean {
    return this._timelineShowAssignmentDay;
  }

  set timelineShowAssignmentDay(value: boolean) {
    this._timelineShowAssignmentDay = value;
    void this.saveValue(StudyoSettingsKeys.timelineShowAssignmentDay, value);
  }

  @computed
  get timelineLimitWeeks(): boolean {
    return this._timelineLimitWeeks;
  }

  set timelineLimitWeeks(value: boolean) {
    this._timelineLimitWeeks = value;
    void this.saveValue(StudyoSettingsKeys.timelineLimitWeeks, value);
  }

  //
  // Planner
  //

  @computed
  get plannerPeriodHeight(): Types.PlannerPeriodHeightKind {
    return this._plannerPeriodHeight;
  }

  set plannerPeriodHeight(value: Types.PlannerPeriodHeightKind) {
    this._plannerPeriodHeight = value;
    void this.saveValue(StudyoSettingsKeys.plannerPeriodHeight, value);
  }

  @computed
  get plannerSectionFilters(): string[] {
    // We return a slice of the observable array to prevent having a reference.
    return this._plannerSectionFilters.slice();
  }

  set plannerSectionFilters(value: string[]) {
    // We want to present setting a reference to an external observable array
    // by doing a slice on the passed array.
    this._plannerSectionFilters = value.slice();
    void this.saveValueForAccount(StudyoSettingsKeys.plannerSectionFilters, this._plannerSectionFilters);
  }

  @computed
  get plannerDisplayWeekends(): boolean {
    return this._plannerDisplayWeekends;
  }

  set plannerDisplayWeekends(value: boolean) {
    this._plannerDisplayWeekends = value;
    void this.saveValue(StudyoSettingsKeys.plannerDisplayWeekends, value);
  }

  @computed
  get plannerShowAssignmentDay(): boolean {
    return this._plannerShowAssignmentDay;
  }

  set plannerShowAssignmentDay(value: boolean) {
    this._plannerShowAssignmentDay = value;
    void this.saveValue(StudyoSettingsKeys.plannerShowAssignmentDay, value);
  }

  @computed
  get plannerContentFilters(): ContentFiltersManager {
    return this._plannerContentFilters;
  }

  savePlannerContentFilters() {
    void this.saveValueForAccount(StudyoSettingsKeys.plannerContentFilters, this._plannerContentFilters.jsonValue);
  }

  //
  // Periods
  //

  @computed
  get periodSectionFilters(): string[] {
    // We return a slice of the observable array to prevent having a reference.
    return this._periodSectionFilters.slice();
  }

  set periodSectionFilters(value: string[]) {
    // We want to present setting a reference to an external observable array
    // by doing a slice on the passed array.
    this._periodSectionFilters = value.slice();
    void this.saveValueForAccount(StudyoSettingsKeys.periodSectionFilters, this._periodSectionFilters);
  }

  @computed
  get periodShowAssignmentDay(): boolean {
    return this._periodShowAssignmentDay;
  }

  set periodShowAssignmentDay(value: boolean) {
    this._periodShowAssignmentDay = value;
    void this.saveValue(StudyoSettingsKeys.periodShowAssignmentDay, value);
  }

  @computed
  get periodContentFilters(): ContentFiltersManager {
    return this._periodContentFilters;
  }

  savePeriodContentFilters() {
    void this.saveValueForAccount(StudyoSettingsKeys.periodContentFilters, this._periodContentFilters.jsonValue);
  }

  //
  // UI
  //

  @computed
  get backgroundOpacity(): number {
    return this._backgroundOpacity;
  }

  set backgroundOpacity(value: number) {
    this._backgroundOpacity = Math.max(0.5, Math.min(1, value));
    void this.saveValue(StudyoSettingsKeys.backgroundOpacity, this._backgroundOpacity);
  }

  @computed
  get backgroundOnlyOnHeader(): boolean {
    return this._backgroundOnlyOnHeader;
  }

  set backgroundOnlyOnHeader(value: boolean) {
    this._backgroundOnlyOnHeader = value;
    void this.saveValue(StudyoSettingsKeys.backgroundOnlyOnHeader, value);
  }

  //
  // Lifecycle
  //

  @action
  clear() {
    this._announcementDateToday = this._defaultAnnouncementDateToday;
    void this.clearValue(StudyoSettingsKeys.announcementDateToday);

    this._newTaskDueNextPeriod = this._defaultNewTaskDueNextPeriod;
    void this.clearValue(StudyoSettingsKeys.newTaskDueNextPeriod);

    this._dailyContentDisplayKind = this._defaultDailyContentDisplayKind;
    void this.clearValue(StudyoSettingsKeys.dailyContentDisplayKind);

    this._dailyContentPresentationKind = this._defaultDailyContentPresentationKind;
    void this.clearValue(StudyoSettingsKeys.dailyContentPresentationKind);

    this._dailyPeriodHeight = this._defaultDailyPeriodHeight;
    void this.clearValue(StudyoSettingsKeys.dailyPeriodHeight);

    this._dailyShowAssignmentDay = this._defaultDailyShowAssignmentDay;
    void this.clearValue(StudyoSettingsKeys.dailyShowAssignmentDay);

    this._weeklyContentDisplayKind = this._defaultWeeklyContentDisplayKind;
    void this.clearValue(StudyoSettingsKeys.weeklyContentDisplayKind);

    this._weeklyPeriodHeight = this._defaultWeeklyPeriodHeight;
    void this.clearValue(StudyoSettingsKeys.weeklyPeriodHeight);

    this._monthlySectionFilters = this._defaultMonthlySectionFilters;
    void this.clearValue(StudyoSettingsKeys.monthlySectionFilters);

    this._monthlyContentFilters.resetFilters();
    void this.clearValue(StudyoSettingsKeys.monthlyContentFilters);

    this._timelineContentFilters.resetFilters();
    void this.clearValue(StudyoSettingsKeys.timelineContentFilters);

    this._timelineCelSize = this._defaultTimelineCellSize;
    void this.clearValue(StudyoSettingsKeys.timelineCellSize);

    this._timelineShowAssignmentDay = this._defaultTimelineShowAssignmentDay;
    void this.clearValue(StudyoSettingsKeys.timelineShowAssignmentDay);

    this._timelineLimitWeeks = this._defaultTimelineLimitWeeks;
    void this.clearValue(StudyoSettingsKeys.timelineLimitWeeks);

    this._periodShowAssignmentDay = this._defaultPeriodShowAssignmentDay;
    void this.clearValue(StudyoSettingsKeys.periodShowAssignmentDay);

    this._periodContentFilters.resetFilters();
    void this.clearValue(StudyoSettingsKeys.periodContentFilters);

    this._periodSectionFilters = this._defaultPeriodSectionFilters;
    void this.clearValue(StudyoSettingsKeys.periodSectionFilters);

    this._plannerContentFilters.resetFilters();
    void this.clearValue(StudyoSettingsKeys.plannerContentFilters);

    this._plannerDisplayWeekends = this._defaultPlannerDisplayWeekends;
    void this.clearValue(StudyoSettingsKeys.plannerDisplayWeekends);

    this._plannerPeriodHeight = this._defaultPlannerPeriodHeight;
    void this.clearValue(StudyoSettingsKeys.plannerPeriodHeight);

    this._plannerSectionFilters = this._defaultPlannerSectionFilters;
    void this.clearValue(StudyoSettingsKeys.plannerSectionFilters);

    this._plannerShowAssignmentDay = this._defaultPlannerShowAssignmentDay;
    void this.clearValue(StudyoSettingsKeys.plannerShowAssignmentDay);

    this._backgroundOpacity = this._defaultBackgroundOpacity;
    void this.clearValue(StudyoSettingsKeys.backgroundOpacity);

    this._backgroundOnlyOnHeader = this._defaultBackgroundOnlyOnHeader;
    void this.clearValue(StudyoSettingsKeys.backgroundOnlyOnHeader);
  }

  /* Overridden methods */

  protected async loadData() {
    if (!this.startLoadingData()) {
      return;
    }

    try {
      await this.loadGenericPreferences();
      await this.loadDailyPreferences();
      await this.loadWeeklyPreferences();
      await this.loadMonthlyPreferences();
      await this.loadTimelinePreferences();
      await this.loadPeriodsPreferences();
      await this.loadPlannerPreferences();
      await this.loadUIPreferences();

      this.stopLoadingData(undefined);
    } catch (error) {
      this.stopLoadingData(error as Error);
    }
  }

  /* Private methods */

  private async loadGenericPreferences() {
    const [announcementDateToday, newTaskDueNextPeriod] = await Promise.all([
      this.getStoredValue<boolean>(StudyoSettingsKeys.announcementDateToday, this._defaultAnnouncementDateToday),
      this.getStoredValue<boolean>(StudyoSettingsKeys.newTaskDueNextPeriod, this._defaultNewTaskDueNextPeriod)
    ]);

    runInAction(() => {
      this._announcementDateToday = announcementDateToday;
      this._newTaskDueNextPeriod = newTaskDueNextPeriod;
    });
  }

  private async loadDailyPreferences() {
    const [dailyPeriodHeight, dailyContentDisplayKind, dailyContentPresentationKind, dailyShowAssignmentDay] =
      await Promise.all([
        this.getStoredValue<Types.DailyPeriodHeightKind>(
          StudyoSettingsKeys.dailyPeriodHeight,
          this._defaultDailyPeriodHeight
        ),
        this.getStoredValue<Types.DailyContentDisplayKind>(
          StudyoSettingsKeys.dailyContentDisplayKind,
          this._defaultDailyContentDisplayKind
        ),
        this.getStoredValue<Types.DailyContentPresentationKind>(
          StudyoSettingsKeys.dailyContentPresentationKind,
          this._defaultDailyContentPresentationKind
        ),
        this.getStoredValue<boolean>(StudyoSettingsKeys.dailyShowAssignmentDay, this._defaultDailyShowAssignmentDay)
      ]);

    runInAction(() => {
      this._dailyPeriodHeight = dailyPeriodHeight;
      this._dailyContentDisplayKind = dailyContentDisplayKind;
      this._dailyContentPresentationKind = dailyContentPresentationKind;
      this._dailyShowAssignmentDay = dailyShowAssignmentDay;
    });
  }

  private async loadWeeklyPreferences() {
    const [weeklyPeriodHeight, weeklyContentDisplayKind, weeklyDisplayWeekends] = await Promise.all([
      this.getStoredValue<Types.WeeklyPeriodHeightKind>(
        StudyoSettingsKeys.weeklyPeriodHeight,
        this._defaultWeeklyPeriodHeight
      ),
      this.getStoredValue<Types.WeeklyContentDisplayKind>(
        StudyoSettingsKeys.weeklyContentDisplayKind,
        this._defaultWeeklyContentDisplayKind
      ),
      this.getStoredValue(StudyoSettingsKeys.weeklyDisplayWeekends, this._defaultWeeklyDisplayWeekends)
    ]);

    runInAction(() => {
      this._weeklyPeriodHeight = weeklyPeriodHeight;
      this._weeklyContentDisplayKind = weeklyContentDisplayKind;
      this._weeklyDisplayWeekends = weeklyDisplayWeekends;
    });
  }

  private async loadMonthlyPreferences() {
    const [monthlySectionFilters, monthlyContentFilters] = await Promise.all([
      this.getStoredValueForAccount(StudyoSettingsKeys.monthlySectionFilters, []),
      this.getStoredValueForAccount(StudyoSettingsKeys.monthlyContentFilters, {})
    ]);

    runInAction(() => {
      this._monthlySectionFilters = monthlySectionFilters;
      this._monthlyContentFilters = new AppContentFiltersManager(monthlyContentFilters);
    });
  }

  private async loadTimelinePreferences() {
    const [timelineContentFilters, timelineCellSize, timelineShowAssignmentDay] = await Promise.all([
      this.getStoredValueForAccount(StudyoSettingsKeys.timelineContentFilters, {}),
      this.getStoredValue<Types.TimelineCellSizeKind>(
        StudyoSettingsKeys.timelineCellSize,
        this._defaultTimelineCellSize
      ),
      this.getStoredValue(StudyoSettingsKeys.timelineShowAssignmentDay, this._defaultTimelineShowAssignmentDay)
    ]);

    runInAction(() => {
      this._timelineContentFilters = new AppContentFiltersManager(timelineContentFilters);
      this._timelineCelSize = timelineCellSize;
      this._timelineShowAssignmentDay = timelineShowAssignmentDay;
    });
  }

  private async loadPeriodsPreferences() {
    const [periodShowAssignmentDay, periodContentFilters, periodSectionFilters] = await Promise.all([
      this.getStoredValue(StudyoSettingsKeys.periodShowAssignmentDay, this._defaultPeriodShowAssignmentDay),
      this.getStoredValueForAccount(StudyoSettingsKeys.periodContentFilters, {}),
      this.getStoredValueForAccount(StudyoSettingsKeys.periodSectionFilters, [])
    ]);

    runInAction(() => {
      this._periodShowAssignmentDay = periodShowAssignmentDay;
      this._periodContentFilters = new AppContentFiltersManager(periodContentFilters);
      this._periodSectionFilters = periodSectionFilters;
    });
  }

  private async loadPlannerPreferences() {
    const [plannerPeriodHeight, plannerSectionFilters, plannerDisplayWeekends, plannerContentFilters] =
      await Promise.all([
        this.getStoredValue<Types.PlannerPeriodHeightKind>(
          StudyoSettingsKeys.plannerPeriodHeight,
          this._defaultPlannerPeriodHeight
        ),
        this.getStoredValueForAccount(StudyoSettingsKeys.plannerSectionFilters, []),
        this.getStoredValue(StudyoSettingsKeys.plannerDisplayWeekends, this._defaultPlannerDisplayWeekends),
        this.getStoredValueForAccount(StudyoSettingsKeys.plannerContentFilters, {})
      ]);

    runInAction(() => {
      this._plannerPeriodHeight = plannerPeriodHeight;
      this._plannerSectionFilters = plannerSectionFilters;
      this._plannerDisplayWeekends = plannerDisplayWeekends;
      this._plannerContentFilters = new AppContentFiltersManager(plannerContentFilters);
    });
  }

  private async loadUIPreferences() {
    const [backgroundOpacity, backgroundOnlyOnHeader] = await Promise.all([
      this.getStoredValue<number>(StudyoSettingsKeys.backgroundOpacity, this._defaultBackgroundOpacity),
      this.getStoredValue<boolean>(StudyoSettingsKeys.backgroundOnlyOnHeader, this._defaultBackgroundOnlyOnHeader)
    ]);

    runInAction(() => {
      this._backgroundOpacity = backgroundOpacity;
      this._backgroundOnlyOnHeader = backgroundOnlyOnHeader;
    });
  }

  /**
   * Fetch the stored value for a specific key in the storage. If no value exists,
   * it returns the default value passed.
   *
   * @param key Key used to store the value
   * @param defaultValue Value used if no value exists in storage
   */
  private async getStoredValue<T>(key: string, defaultValue: T): Promise<T> {
    const value = await this._localStorageResolver().get<T>(key);
    return value ?? defaultValue;
  }

  /**
   * Saves a value in the storage with a certain key. If a value already exists, it will be overridden.
   *
   * @param key Key use to store the value.
   * @param value Value to store.
   */
  @action
  private async saveValue<T>(key: string, value: T) {
    return this._localStorageResolver().set(key, value);
  }

  /**
   * Clear the value in the storage for the specified key.
   * @param key Key used to clear the value
   */
  @action
  private async clearValue(key: string): Promise<void> {
    return this._localStorageResolver().delete(key);
  }

  /**
   * Fetch a dictionnary for a specific key in the storage indexed by account id. If no value exists,
   * it returns the default value passed.
   *
   * Use this when getting a value with is stored by account id.
   *
   * @param key Key used to store the value
   * @param defaultValue Value used if no value exists in storage
   */
  private async getStoredValueForAccount<T>(key: string, defaultValue: T): Promise<T> {
    let value: T | undefined;

    const existingValues = await this._localStorageResolver().get<Dictionary<T>>(key);

    if (existingValues != null) {
      value = existingValues[this.accountId];
    }

    return value ?? defaultValue;
  }

  /**
   * Save a value for the current account id in the storage. It will update the dictionnary stored,
   * which is indexed by account id.
   *
   * @param key Key used to store the value
   * @param value Value to store
   */
  private async saveValueForAccount<T>(key: string, value: T): Promise<Dictionary<T>> {
    const existingValues = await this._localStorageResolver().get<Dictionary<T>>(key);
    const newValues = existingValues ?? {};
    newValues[this.accountId] = value;
    await this._localStorageResolver().set(key, newValues);
    return newValues;
  }
}
