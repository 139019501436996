import {
  CalendarMonth,
  CalendarToday,
  DateRange,
  TableRows,
  Troubleshoot,
  VerticalSplit,
  ViewTimeline
} from '@mui/icons-material';
import TodayIcon from '@mui/icons-material/Today';
import { Box, CircularProgress, Stack, SxProps, useTheme } from '@mui/material';
import { useNavigateAsync } from '@shared/utils';
import { useBackgroundImage } from '@studyo/UseBackgroundImageHook.ts';
import { AuthorizationRoleCondition, ConfirmDialog, ImpersonatingIndicator } from '@studyo/components';
import { BackgroundImage } from '@studyo/views/agenda/BackgroundImage.tsx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router';
import { RouteTemplates } from '../../../Routes';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { BottomTabBar, BottomTabBarItem } from '../../../components';
import { AgendaScreenKind } from '../../../services';
import { ErrorScreen } from '../../ErrorScreen.tsx';
import { PreparingSchoolScreen } from '../preparing';

export interface AgendaLayoutProps {
  sx?: SxProps;
  className?: string;
}

export const AgendaLayout = observer((props: AgendaLayoutProps) => {
  const { accountService, localizationService, navigationService, reactRouterRouteService, viewModelFactory } =
    useStudyoServices();
  const { sx = [], className } = props;
  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.agenda;
  const { backgroundImage, backgroundOnlyOnHeader } = useBackgroundImage();

  const location = useLocation();
  const navigate = useNavigateAsync();
  const params = useParams();
  const configId = params.configId ?? '';
  const accountId = params.accountId ?? '';

  const viewModel = useMemo(() => viewModelFactory.createBaseAgenda(), [configId, accountId]);
  const [isInitializing, setIsInitializing] = useState(true);
  const [hasSetCurrentAccountError, setHasSetCurrentAccountError] = useState(false);
  const [displayParentNoConfigDialog, setDisplayParentNoConfigDialog] = useState(false);
  const [displayStudentNoConfigDialog, setDisplayStudentNoConfigDialog] = useState(false);
  const isDisplayingUserModals = useRef<boolean>(false);

  useEffect(() => {
    void initialize();
    // Gives a better user experience to have a delay. Too sudden otherwise.
    const displayModalsTimout = setTimeout(() => void displayModals(), 1000);
    return () => clearTimeout(displayModalsTimout);
  }, []);

  async function initializeAccount(accountId: string) {
    try {
      setIsInitializing(true);
      await accountService.setCurrentDisplayedAccount(accountId);
    } catch {
      setHasSetCurrentAccountError(true);
    } finally {
      setIsInitializing(false);
    }
  }

  async function displayUserInfoModals() {
    const data = accountService.displayedAccountData;

    await when(() => data.hasData);

    if (!isDisplayingUserModals.current) {
      isDisplayingUserModals.current = true;

      if (
        accountService.isLoggedIn &&
        !data.isImpersonating &&
        data.config.type === 'managed' &&
        data.account.role === 'student'
      ) {
        if (
          accountService.currentAccount != null &&
          accountService.currentAccount !== 'super-admin' &&
          accountService.currentAccount !== 'super-observer' &&
          accountService.currentAccount.role === 'student'
        ) {
          if (
            accountService.currentAccount.firstName.length === 0 ||
            accountService.currentAccount.lastName.length === 0
          ) {
            // This fills the public first and last name.
            await navigationService.navigateToAskNameModal();
          }

          const hasSelectedSections = data.account.settings.selectedSectionIds.length > 0;

          if (!viewModel.isPreparing && !hasSelectedSections) {
            await navigationService.navigateToCourseSelection();
          }
        }
      }

      isDisplayingUserModals.current = false;
    }
  }

  async function initialize() {
    return initializeAccount(accountId);
  }

  async function displayModals() {
    await displayUserInfoModals();

    if (accountService.isParentAndNoCurrentConfig) {
      showParentNoConfigDialog();
    } else if (accountService.isStudentAndNoCurrentConfig || accountService.isTeacherAndNoCurrentConfig) {
      showStudentNoConfigDialog();
    }
  }

  const hideParentNoConfigDialog = () => {
    setDisplayParentNoConfigDialog(false);
  };

  const showParentNoConfigDialog = () => {
    setDisplayParentNoConfigDialog(true);
  };

  const hideStudentNoConfigDialog = () => {
    setDisplayStudentNoConfigDialog(false);
  };

  const showStudentNoConfigDialog = () => {
    setDisplayStudentNoConfigDialog(true);
  };

  const navigateToSubview = (kind: AgendaScreenKind) => {
    const destination = navigationService.getAgendaScreenLocation(kind, configId, accountId);
    void navigate(destination);
  };

  if (isInitializing) {
    return (
      <Stack
        sx={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.studyo.agenda.rootBackgroundColor
        }}
      >
        <CircularProgress />
      </Stack>
    );
  }

  if (hasSetCurrentAccountError) {
    return (
      <ErrorScreen
        sx={{ flex: 1 }}
        title={strings.accountNotFoundErrorTitle}
        subtitle={strings.accountNotFoundErrorMessage}
      />
    );
  }

  // At this point, we have a current account.
  if (viewModel.isPreparing) {
    return <PreparingSchoolScreen sx={{ width: '100%', height: '100%' }} configId={configId} accountId={accountId} />;
  }

  return (
    <Box
      sx={{
        ...sx,
        backgroundColor: theme.studyo.agenda.rootBackgroundColor,
        display: 'flex',
        flexDirection: 'column'
      }}
      className={className}
    >
      <ImpersonatingIndicator />
      <BackgroundImage image={backgroundImage} height={backgroundOnlyOnHeader ? 144 : undefined} />

      <Box
        sx={{
          flex: 1,
          overflow: 'hidden',
          display: 'flex'
        }}
      >
        <Outlet />
      </Box>
      <BottomTabBar>
        <BottomTabBarItem
          sx={{ flex: { xs: 1, sm: 0 } }}
          title={localizationService.localizedStrings.studyo.agenda.dailyViewNavTitle}
          icon={(size, color) => <TodayIcon sx={{ width: size, height: size, color }} />}
          onClick={() => navigateToSubview('daily')}
          isActive={reactRouterRouteService.isRouteActive(RouteTemplates.config.account.agenda.daily, false, location)}
        />
        <BottomTabBarItem
          sx={{ flex: { xs: 1, sm: 0 } }}
          title={localizationService.localizedStrings.studyo.agenda.weeklyViewNavTitle}
          icon={(size, color) => <DateRange sx={{ width: size, height: size, color }} />}
          onClick={() => navigateToSubview('weekly')}
          isActive={reactRouterRouteService.isRouteActive(RouteTemplates.config.account.agenda.weekly, false, location)}
          minimumVisibleWidth="sm"
        />
        <BottomTabBarItem
          sx={{ flex: { xs: 1, sm: 0 } }}
          title={localizationService.localizedStrings.studyo.agenda.monthlyViewNavTitle}
          icon={(size, color) => <CalendarMonth sx={{ width: size, height: size, color }} />}
          onClick={() => navigateToSubview('monthly')}
          isActive={reactRouterRouteService.isRouteActive(
            RouteTemplates.config.account.agenda.monthly,
            false,
            location
          )}
          minimumVisibleWidth="sm"
        />
        <BottomTabBarItem
          sx={{ flex: { xs: 1, sm: 0 } }}
          title={localizationService.localizedStrings.studyo.agenda.yearlyViewNavTitle}
          // Icon is bigger for some reason, so removing 2px
          icon={(size, color) => <CalendarToday sx={{ width: size - 2, height: size - 2, color }} />}
          onClick={() => navigateToSubview('yearly')}
          isActive={reactRouterRouteService.isRouteActive(RouteTemplates.config.account.agenda.yearly, false, location)}
          minimumVisibleWidth="sm"
        />
        <BottomTabBarItem
          sx={{ flex: { xs: 1, sm: 0 } }}
          title={localizationService.localizedStrings.studyo.agenda.timelineViewNavTitle}
          icon={(size, color) => <ViewTimeline sx={{ width: size, height: size, color }} />}
          onClick={() => navigateToSubview('timeline')}
          isActive={reactRouterRouteService.isRouteActive(
            RouteTemplates.config.account.agenda.timeline,
            false,
            location
          )}
          badgeCount={viewModel.lateTasksCount}
        />
        <AuthorizationRoleCondition allowedRoles={['individual', 'teacher', 'super-admin', 'super-observer']}>
          <BottomTabBarItem
            sx={{ flex: { xs: 1, sm: 0 } }}
            title={localizationService.localizedStrings.studyo.agenda.plannerViewNavTitle}
            icon={(size, color) => <TableRows sx={{ width: size, height: size, color }} />}
            onClick={() => navigateToSubview('planner')}
            isActive={reactRouterRouteService.isRouteActive(
              RouteTemplates.config.account.agenda.planner,
              false,
              location
            )}
            minimumVisibleWidth="sm"
          />
        </AuthorizationRoleCondition>
        <AuthorizationRoleCondition allowedRoles={['teacher', 'super-admin', 'super-observer']}>
          <BottomTabBarItem
            sx={{ flex: { xs: 1, sm: 0 } }}
            title={localizationService.localizedStrings.studyo.agenda.workloadViewNavTitle}
            icon={(size, color) => <Troubleshoot sx={{ width: size, height: size, color }} />}
            onClick={() => navigateToSubview('workload')}
            isActive={reactRouterRouteService.isRouteActive(
              RouteTemplates.config.account.agenda.workload,
              false,
              location
            )}
            minimumVisibleWidth="sm"
          />
        </AuthorizationRoleCondition>
        <BottomTabBarItem
          sx={{ flex: { xs: 1, sm: 0 } }}
          title={localizationService.localizedStrings.studyo.agenda.periodsViewNavTitle}
          icon={(size, color) => <VerticalSplit sx={{ width: size, height: size, color }} />}
          onClick={() => navigateToSubview('periods')}
          isActive={reactRouterRouteService.isRouteActive(
            RouteTemplates.config.account.agenda.periods,
            false,
            location
          )}
          minimumVisibleWidth="sm"
        />
      </BottomTabBar>
      <ConfirmDialog
        open={displayParentNoConfigDialog}
        title={strings.parentNewSchoolYearAlertTitle}
        description={strings.parentNewSchoolYearAlertDescription}
        confirmTitle={localizationService.localizedStrings.studyo.utils.alertDialogOk}
        onConfirmPress={hideParentNoConfigDialog}
        onClose={hideParentNoConfigDialog}
      />
      <ConfirmDialog
        open={displayStudentNoConfigDialog}
        title={strings.studentNewSchoolYearAlertTitle}
        description={strings.studentNewSchoolYearAlertDescription}
        confirmTitle={localizationService.localizedStrings.studyo.utils.alertDialogOk}
        onConfirmPress={hideStudentNoConfigDialog}
        onClose={hideStudentNoConfigDialog}
      />
    </Box>
  );
});
