import { AuthorizationRole } from '@shared/models/types';
import { EnglishSupportStrings, FrenchSupportStrings, SupportStrings } from './SupportStrings';
import { EnglishWorkloadStrings, FrenchWorkloadStrings, WorkloadStrings } from './WorkloadStrings';
import { DailyStrings, EnglishDailyStrings, FrenchDailyStrings } from './daily/DailyStrings';
import { EnglishMonthlyStrings, FrenchMonthlyStrings, MonthlyStrings } from './month/MonthlyStrings';
import { EnglishPeriodsStrings, FrenchPeriodsStrings, PeriodsStrings } from './periods/PeriodsStrings';
import { EnglishPlannerStrings, FrenchPlannerStrings, PlannerStrings } from './planner/PlannerStrings';
import { EnglishSettingStrings, FrenchSettingStrings, SettingStrings } from './settings/SettingStrings';
import { EnglishTimelineStrings, FrenchTimelineStrings, TimelineStrings } from './timeline/TimelineStrings';
import { EnglishWeeklyStrings, FrenchWeeklyStrings, WeeklyStrings } from './weekly/WeeklyStrings';

export interface AgendaStrings {
  readonly dailyViewNavTitle: string;
  readonly weeklyViewNavTitle: string;
  readonly monthlyViewNavTitle: string;
  readonly yearlyViewNavTitle: string;
  readonly timelineViewNavTitle: string;
  readonly plannerViewNavTitle: string;
  readonly periodsViewNavTitle: string;
  readonly workloadViewNavTitle: string;

  readonly contentFilterButton: string;
  readonly sectionFilterButton: string;

  readonly parentNewSchoolYearAlertTitle: string;
  readonly parentNewSchoolYearAlertDescription: string;
  readonly parentOf: string;

  readonly studentNewSchoolYearAlertTitle: string;
  readonly studentNewSchoolYearAlertDescription: string;

  readonly errorMessage: string;
  readonly accountNotFoundErrorTitle: string;
  readonly accountNotFoundErrorMessage: string;

  readonly viewingAs: (name: string, impersonatingRole?: AuthorizationRole) => string;

  readonly daily: DailyStrings;
  readonly weekly: WeeklyStrings;
  readonly monthly: MonthlyStrings;
  readonly periods: PeriodsStrings;
  readonly planner: PlannerStrings;
  readonly setting: SettingStrings;
  readonly support: SupportStrings;
  readonly timeline: TimelineStrings;
  readonly workload: WorkloadStrings;
}

export const EnglishAgendaStrings: AgendaStrings = {
  dailyViewNavTitle: 'Day',
  weeklyViewNavTitle: 'Week',
  monthlyViewNavTitle: 'Month',
  yearlyViewNavTitle: 'Year',
  timelineViewNavTitle: 'Tasks',
  plannerViewNavTitle: 'Teacher',
  periodsViewNavTitle: 'Periods',
  workloadViewNavTitle: 'Insights',

  contentFilterButton: 'Tasks',
  sectionFilterButton: 'Sections',

  parentNewSchoolYearAlertTitle: 'New school year',
  parentNewSchoolYearAlertDescription:
    'Your child must invite you in order to allow you to see his planner in the gear menu.',
  parentOf: 'Parent of:',

  studentNewSchoolYearAlertTitle: 'New school year',
  studentNewSchoolYearAlertDescription: 'A new school year is now available in the profile menu.',

  errorMessage: 'Something went wrong while fetching your data.',
  accountNotFoundErrorTitle: 'Record not found',
  accountNotFoundErrorMessage: "This record either does not exist or you don't have access to it.",

  daily: EnglishDailyStrings,
  weekly: EnglishWeeklyStrings,
  monthly: EnglishMonthlyStrings,
  periods: EnglishPeriodsStrings,
  planner: EnglishPlannerStrings,
  setting: EnglishSettingStrings,
  support: EnglishSupportStrings,
  timeline: EnglishTimelineStrings,
  workload: EnglishWorkloadStrings,

  viewingAs: (name, impersonatingRole?) => {
    switch (impersonatingRole) {
      case 'super-admin':
        return `Planner of ${name} (as a super-administrator, read-only)`;
      case 'super-observer':
        return `Planner of ${name} (as a super-observer, read-only)`;
      case 'admin':
        return `Planner of ${name} (as an administrator, read-only)`;
      case 'teacher':
        return `Planner of ${name} (as their teacher, read-only)`;
      case 'parent':
        return `Planner of ${name} (as their parent, read-only)`;
      default:
        return `Planner of ${name} (read-only)`;
    }
  }
};

export const FrenchAgendaStrings: AgendaStrings = {
  dailyViewNavTitle: 'Jour',
  weeklyViewNavTitle: 'Semaine',
  monthlyViewNavTitle: 'Mois',
  yearlyViewNavTitle: 'Année',
  timelineViewNavTitle: 'Tâches',
  plannerViewNavTitle: 'Prof',
  periodsViewNavTitle: 'Périodes',
  workloadViewNavTitle: 'Insights',

  contentFilterButton: 'Tâches',
  sectionFilterButton: 'Groupes',

  parentNewSchoolYearAlertTitle: 'Nouvelle année scolaire',
  parentNewSchoolYearAlertDescription:
    'Votre enfant doit vous inviter à partir du menu engrenage pour que vous puissiez avoir accès à son agenda.',
  parentOf: 'Parent de :',

  studentNewSchoolYearAlertTitle: 'Nouvelle année scolaire',
  studentNewSchoolYearAlertDescription: 'Une nouvelle année scolaire est maintenant disponible dans le menu de profil.',

  errorMessage: 'Le chargement de vos données a échoué.',
  accountNotFoundErrorTitle: 'Fiche non trouvée',
  accountNotFoundErrorMessage: 'Cette fiche n’existe pas ou vous n’y avez pas accès.',

  daily: FrenchDailyStrings,
  weekly: FrenchWeeklyStrings,
  monthly: FrenchMonthlyStrings,
  periods: FrenchPeriodsStrings,
  planner: FrenchPlannerStrings,
  setting: FrenchSettingStrings,
  support: FrenchSupportStrings,
  timeline: FrenchTimelineStrings,
  workload: FrenchWorkloadStrings,

  viewingAs: (name, impersonatingRole?) => {
    switch (impersonatingRole) {
      case 'super-admin':
        return `Agenda de ${name} (en tant que super-administrateur, lecture-seule)`;
      case 'super-observer':
        return `Agenda de ${name} (en tant que super-observateur, lecture-seule)`;
      case 'admin':
        return `Agenda de ${name} (en tant qu’administrateur, lecture-seule)`;
      case 'teacher':
        return `Agenda de ${name} (en tant qu’enseignant, lecture-seule)`;
      case 'parent':
        return `Agenda de ${name} (en tant que parent, lecture-seule)`;
      default:
        return `Agenda de ${name} (en lecture-seule)`;
    }
  }
};
