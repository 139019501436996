import { AccountUtils } from '@shared/components/utils';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { AccountSummary } from '@shared/models/config';
import { Color } from '@shared/models/types';
import { NavigateFunctionAsync } from '@shared/utils';
import { computed, makeObservable } from 'mobx';
import { Location } from 'react-router';
import { AccountService, NavigationService } from '../../../services';

export interface ProfileMenuProfileViewModel {
  readonly firstName: string;
  readonly lastName: string;
  readonly displayName: string;
  readonly schoolName: string;
  readonly schoolTitle: string;
  readonly color: Color | undefined;
  readonly isImpersonating: boolean;

  selectProfile(location: Location, navigate: NavigateFunctionAsync): Promise<void>;
}

export class AppProfileMenuProfileViewModel implements ProfileMenuProfileViewModel {
  constructor(
    protected readonly _accountSummary: AccountSummary | undefined,
    protected readonly _childAccountSummary: AccountSummary | undefined,
    protected readonly _accountService: AccountService,
    protected readonly _navigationService: NavigationService,
    private readonly _onSwitchProfile: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get firstName(): string {
    // Do not use private first name when viewing a child's profile.
    return (
      this._childAccountSummary?.firstName ??
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      (this._accountSummary?.profile.privateFirstName || this._accountSummary?.firstName) ??
      ''
    );
  }

  @computed
  get lastName(): string {
    // Do not use private last name when viewing a child's profile.
    return (
      this._childAccountSummary?.lastName ??
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      (this._accountSummary?.profile.privateLastName || this._accountSummary?.lastName) ??
      ''
    );
  }

  @computed
  get displayName(): string {
    return AccountUtils.getDisplayFirstLastName(
      this._childAccountSummary ?? this._accountSummary,
      '',
      this._childAccountSummary == null
    );
  }

  @computed
  get schoolName(): string {
    return (
      this._childAccountSummary?.configurationSummary?.schoolName ??
      this._accountSummary?.configurationSummary?.schoolName ??
      'n/a'
    );
  }

  @computed
  get schoolTitle(): string {
    const summary = this._childAccountSummary?.configurationSummary ?? this._accountSummary?.configurationSummary;

    return summary != null ? SchoolYearConfigurationUtils.displayTitle(summary) : 'n/a';
  }

  @computed
  get color(): Color | undefined {
    return this._childAccountSummary?.profile.avatarColor ?? this._accountSummary?.profile.avatarColor;
  }

  @computed
  get isImpersonating() {
    // Root admins and observers have no account summary.
    return (
      (this._accountSummary == null || this._accountSummary.role === 'teacher') && this._childAccountSummary != null
    );
  }

  async selectProfile(location: Location, navigate: NavigateFunctionAsync) {
    const accountId = this._childAccountSummary?.id ?? this._accountSummary?.id;
    const configId = this._childAccountSummary?.configId ?? this._accountSummary?.configId;

    if (accountId != null) {
      await this._accountService.setCurrentDisplayedAccount(accountId);
      this._onSwitchProfile();
      await this._navigationService.closeAllModals();
      await this._navigationService.navigateToSamePage(configId!, accountId, location, navigate);
    }
  }
}
