import { Box, Menu, MenuItem, Stack, SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { Day } from '@shared/models/types';
import { DialogResult } from '@shared/services';
import {
  ConfirmDialog,
  DataPresenter,
  DialogActionButtons,
  DialogButton,
  ResponsiveDialog,
  SaveDialog,
  useSaveDialog,
  useSaveDialogWithParam
} from '@studyo/components';
import { PlannerPeriodInfoView } from '@studyo/views';
import { observer } from 'mobx-react-lite';
import { useMemo, useRef, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';

type MoveOperation = 'none' | 'move-left' | 'unskip-move-left-from-next' | 'skip-move-right' | 'move-right';

export interface PlannerPeriodInfoScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  day: Day;
  periodTag: string;
  sectionId: string;
}

export const PlannerPeriodInfoScreen = observer(
  ({ day, sx = [], className, periodTag, sectionId, onCancel, onSuccess }: PlannerPeriodInfoScreenProps) => {
    const { localizationService, viewModelFactory } = useStudyoServices();
    const viewModel = useMemo(
      () => viewModelFactory.createPlannerPeriodInfo(day, periodTag, sectionId, onSuccess!, onCancel!),
      [day, periodTag, sectionId]
    );

    const [displayMoveMenu, setDisplayMoveMenu] = useState(false);
    const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
    const [savePromise, startSave, resetSave, retrySave] = useSaveDialog(() => viewModel.save());

    async function startMoveOperation(operation: MoveOperation) {
      switch (operation) {
        case 'move-left':
          await viewModel.moveContentToPreviousPeriod();
          break;
        case 'unskip-move-left-from-next':
          await viewModel.unskipPeriodAndMoveContentFromNext();
          break;
        case 'skip-move-right':
          await viewModel.skipPeriodAndMoveContentToNext();
          break;
        case 'move-right':
          await viewModel.moveContentToNextPeriod();
          break;
      }
    }

    const [movePromise, startMove, resetMove, retryMove] = useSaveDialogWithParam<MoveOperation>(
      'none',
      startMoveOperation
    );

    const theme = useTheme();
    const strings = localizationService.localizedStrings.studyo.agenda.planner.periodInfoStrings;
    const moveButtonRef = useRef<HTMLDivElement>(null);

    const onMoveButtonClick = () => {
      setDisplayMoveMenu(true);
    };

    const onMoveToPress = async () => {
      hideMoveMenu();
      await viewModel.openContentModification();
    };

    const onMovePeriodLeftPress = () => {
      hideMoveMenu();
      startMove('move-left');
    };

    const onUnskipAndMoveFromNextPeriodLeftPress = () => {
      hideMoveMenu();
      startMove('unskip-move-left-from-next');
    };

    const onSkipAndMovePeriodRightPress = () => {
      hideMoveMenu();
      startMove('skip-move-right');
    };

    const onMovePeriodRightPress = () => {
      hideMoveMenu();
      startMove('move-right');
    };

    const moveToDialogClose = (success: boolean) => {
      resetMove();
      if (success) {
        viewModel.dismiss();
      }
    };

    const onCancelButtonPress = () => {
      if (viewModel.isNoteDirty) {
        setDisplayConfirmModal(true);
      } else {
        viewModel.dismiss();
      }
    };

    const hideMoveMenu = () => {
      setDisplayMoveMenu(false);
    };

    const hideConfirmModal = () => {
      setDisplayConfirmModal(false);
    };

    const saveDialogClose = (success: boolean) => {
      resetSave();
      if (success) {
        viewModel.dismiss();
      }
    };

    const dismiss = () => {
      viewModel.dismiss();
    };

    return (
      <ResponsiveDialog
        sx={sx}
        className={className}
        maxWidth="sm"
        fullWidth={true}
        open={true}
        maxHeight="medium"
        onClose={onCancelButtonPress}
      >
        <DataPresenter
          sx={{ flex: 1, overflow: 'hidden' }}
          dataLoaders={[viewModel.data]}
          renderData={() => {
            const sectionColor = SectionColors.get(viewModel.sectionColor);
            const headerColor = viewModel.isSkipped ? theme.studyo.periods.skippedPeriodColor : sectionColor;
            const headerTextColor = viewModel.isSkipped
              ? theme.studyo.periods.freePeriodTextColor
              : theme.studyo.periods.periodWithSectionTextColor;

            return (
              <Stack
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden'
                }}
              >
                <DialogHeader
                  title={`${viewModel.periodTitle} - ${viewModel.sectionTitle}`}
                  color={headerColor}
                  tintColor={headerTextColor}
                  bandColor={viewModel.isSkipped ? sectionColor : undefined}
                  onClose={() => onCancelButtonPress}
                />
                <PlannerPeriodInfoView sx={{ flex: 1 }} viewModel={viewModel} />
                <DialogActionButtons>
                  {viewModel.canEdit && (
                    <div ref={moveButtonRef}>
                      <DialogButton
                        title={strings.move}
                        type="normal"
                        onPress={onMoveButtonClick}
                        disabled={viewModel.isNoteDirty}
                      />
                    </div>
                  )}

                  <Box
                    sx={{
                      flex: 1
                    }}
                  />

                  {viewModel.isNoteDirty && viewModel.canEdit && (
                    <DialogButton
                      title={strings.cancel}
                      type="cancel"
                      onPress={onCancelButtonPress}
                      minimumScreenSize="sm"
                    />
                  )}

                  {viewModel.isNoteDirty && viewModel.canEdit && (
                    <DialogButton
                      title={strings.save}
                      type="normal"
                      variant="contained"
                      onPress={startSave}
                      minimumScreenSize="sm"
                    />
                  )}

                  {(!viewModel.canEdit || !viewModel.isNoteDirty) && (
                    <DialogButton title={strings.close} type="normal" onPress={dismiss} />
                  )}
                </DialogActionButtons>
                <Menu
                  id="render-props-menu"
                  anchorEl={moveButtonRef.current}
                  open={displayMoveMenu}
                  onClose={hideMoveMenu}
                >
                  <MenuItem onClick={() => void onMoveToPress()}>&nbsp;{strings.moveToOption}</MenuItem>

                  <MenuItem disabled>{strings.moveQuickOptionHeader}</MenuItem>

                  {viewModel.canQuickMovePrevious && (
                    <MenuItem onClick={onMovePeriodLeftPress}>&nbsp;{strings.movePreviousPeriodOption}</MenuItem>
                  )}
                  {viewModel.canQuickUnskipAndMoveFromNext && (
                    <MenuItem onClick={onUnskipAndMoveFromNextPeriodLeftPress}>
                      &nbsp;{strings.unskipAndMovePreviousFromNextPeriodOption}
                    </MenuItem>
                  )}
                  {viewModel.canQuickMoveNext && (
                    <MenuItem onClick={onMovePeriodRightPress}>&nbsp;{strings.moveNextPeriodOption}</MenuItem>
                  )}
                  {viewModel.canQuickSkipAndMoveNext && (
                    <MenuItem onClick={onSkipAndMovePeriodRightPress}>
                      &nbsp;{strings.skipPeriodAndMoveNextOption}
                    </MenuItem>
                  )}
                </Menu>
              </Stack>
            );
          }}
        />
        <ConfirmDialog
          open={displayConfirmModal}
          onClose={hideConfirmModal}
          cancelTitle={strings.confirmDialogCancel}
          onCancelPress={hideConfirmModal}
          confirmTitle={strings.confirmDialogConfirm}
          onConfirmPress={() => viewModel.dismiss()}
          title={strings.confirmDialogTitle}
          description={strings.confirmDialogDescription}
        />
        <SaveDialog
          promise={savePromise}
          retryCall={retrySave}
          onClose={saveDialogClose}
          titles={{
            saving: strings.savingTitle,
            saved: strings.savedTitle,
            error: strings.saveErrorTitle
          }}
          descriptions={{
            saving: strings.savingDescription,
            saved: strings.savedDescription,
            error: strings.saveErrorDescription,
            permanentError: strings.savePermanentErrorDescription
          }}
        />
        <SaveDialog
          promise={movePromise}
          retryCall={retryMove}
          onClose={moveToDialogClose}
          titles={{
            saving: strings.moveSavingTitle,
            saved: strings.moveSavedTitle,
            error: strings.moveSaveErrorTitle
          }}
          descriptions={{
            saving: strings.moveSavingDescription,
            saved: strings.moveSavedDescription,
            error: strings.moveSaveErrorDescription,
            permanentError: strings.moveSavePermanentErrorDescription
          }}
        />
      </ResponsiveDialog>
    );
  }
);
