import {
  CancelScheduleSend,
  CircleOutlined,
  Delete,
  Edit,
  Expand,
  FileCopy,
  Repeat,
  ScheduleSend,
  Send,
  Unarchive
} from '@mui/icons-material';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, SxProps, useTheme } from '@mui/material';
import { SectionColors } from '@shared/models/Colors';
import { DialogResult } from '@shared/services';
import {
  ConfirmDialog,
  DialogActionButtons,
  DialogButton,
  ResponsiveDialog,
  SaveDialog,
  useSaveDialog
} from '@studyo/components';
import { observer } from 'mobx-react-lite';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { useStudyoServices } from '../../../UseStudyoServicesHook';
import { DialogHeader } from '../../../components';
import { TaskInfoView } from '../../../views';

type TaskInfoMenuActions =
  | 'repeat'
  | 'distribute'
  | 'copy'
  | 'delete'
  | 'mark-undone'
  | 'publish'
  | 'publish-to-group'
  | 'unpublish';

export interface TaskInfoScreenProps extends DialogResult<void> {
  sx?: SxProps;
  className?: string;
  contentId: string;
}

export const TaskInfoScreen = observer((props: TaskInfoScreenProps) => {
  const { localizationService, viewModelFactory } = useStudyoServices();
  const { sx = [], className, onCancel, onSuccess, contentId } = props;
  const viewModel = useMemo(() => viewModelFactory.createTaskInfo(contentId, onSuccess!, onCancel!), [contentId]);
  const theme = useTheme();
  const strings = localizationService.localizedStrings.studyo.contents.taskInfo;

  const actionButtonRef = useRef<HTMLDivElement>(null);

  const [displayActionsMenu, setDisplayActionsMenu] = useState(false);
  const [displayDeleteConfirmationDialog, setDisplayDeleteConfirmationDialog] = useState(false);
  const [displayLongTimeSpanDialog, setDisplayLongTimeSpanDialog] = useState(false);
  const [publishPromise, startPublish, resetPublish, retryPublish] = useSaveDialog(() => viewModel.publishToGroup());
  const [unpublishPromise, startUnpublish, resetUnpublish, retryUnpublish] = useSaveDialog(() => viewModel.unpublish());

  const { canEdit, task, canPublish, canToggleState, canDelete, canCopy } = viewModel;

  const headerTitle = viewModel.sectionTitle ?? strings.noCourseTitle;
  const headerSubtitle = viewModel.sectionNumber;

  const headerColor =
    viewModel.color != null ? SectionColors.get(viewModel.color) : theme.studyo.periods.freePeriodColor;

  const headerTintColor =
    viewModel.section != null ? theme.studyo.agenda.timeline.courseTextColor : theme.studyo.periods.freePeriodTextColor;

  const menuItems = useMemo(() => {
    const actions: TaskInfoMenuActions[] = [];

    if (canToggleState && task.state === 'completed') {
      actions.push('mark-undone');
    }

    if (canCopy) {
      actions.push('copy');
    }

    if (canEdit) {
      actions.push('repeat');

      if (canDelete) {
        actions.push('delete');
      }

      if (canPublish) {
        actions.push('distribute');
        actions.push('publish');

        if (task.isPublished) {
          actions.push('unpublish');
        } else {
          actions.push('publish-to-group');
        }
      }
    }

    return actions;
  }, [canEdit, task, canPublish, canToggleState, canDelete, canCopy]);

  const didPressOnMenuItem = async (item: TaskInfoMenuActions) => {
    setDisplayActionsMenu(false);

    switch (item) {
      case 'copy':
        viewModel.copy();
        break;

      case 'delete':
        if (viewModel.isDeleted) {
          viewModel.toggleDeleteState();
        } else {
          await deleteButtonPressed();
        }
        break;

      case 'mark-undone':
        viewModel.toggleState();
        break;

      case 'repeat':
        await viewModel.repeat();
        break;

      case 'distribute':
        await viewModel.distribute();
        break;

      case 'publish':
        await viewModel.publishTo();
        break;

      case 'publish-to-group':
        if (viewModel.hasLongTimeSpan) {
          showLongTimeSpanDialog();
        } else {
          startPublish();
        }
        break;

      case 'unpublish':
        startUnpublish();
        break;
    }
  };

  const closeModal = () => {
    viewModel.dismiss();
  };

  const didClickOnActionButton = () => {
    setDisplayActionsMenu(true);
  };

  const didClickOnCompleteTask = () => {
    viewModel.toggleState();
  };

  const didClickOnEditIcon = () => {
    void viewModel.editContent();
  };

  const closeActionMenu = () => {
    setDisplayActionsMenu(false);
  };

  const deleteButtonPressed = async () => {
    if (viewModel.isLinkedToOtherTasks) {
      const result = await viewModel.showLinkedTasksDelete();
      if (result != 'cancelled') {
        viewModel.dismiss();
      }
    } else {
      showDeleteConfirmationDialog();
    }
  };

  const deleteTask = () => {
    hideDeleteConfirmationDialog();
    viewModel.toggleDeleteState();
  };

  const showDeleteConfirmationDialog = () => {
    setDisplayDeleteConfirmationDialog(true);
  };

  const hideDeleteConfirmationDialog = () => {
    setDisplayDeleteConfirmationDialog(false);
  };

  const longTimeSpanConfirm = () => {
    hideLongTimeSpanDialog();
    startPublish();
  };

  const hideLongTimeSpanDialog = () => {
    setDisplayLongTimeSpanDialog(false);
  };

  const showLongTimeSpanDialog = () => {
    setDisplayLongTimeSpanDialog(true);
  };

  const publishDialogClose = (success: boolean) => {
    resetPublish();
    if (success) {
      viewModel.dismiss();
    }
  };

  const unpublishDialogClose = (success: boolean) => {
    resetUnpublish();
    if (success) {
      viewModel.dismiss();
    }
  };

  const taskInfoMenuAction = (item: TaskInfoMenuActions): { title: string; icon: ReactNode } => {
    switch (item) {
      case 'copy':
        return { title: strings.menuActionCopy, icon: <FileCopy /> };

      case 'delete':
        return viewModel.isDeleted
          ? {
              title: strings.menuActionUndelete,
              icon: <Unarchive />
            }
          : {
              title: strings.menuActionDelete,
              icon: <Delete color="error" />
            };

      case 'mark-undone':
        return { title: strings.menuActionMarkUndone, icon: <CircleOutlined /> };

      case 'publish':
        return { title: strings.menuActionPublish, icon: <ScheduleSend /> };

      case 'publish-to-group':
        return { title: strings.menuActionPublishGroup(viewModel.isLinkedToOtherTasks), icon: <Send /> };

      case 'unpublish':
        return { title: strings.menuActionUnpublish, icon: <CancelScheduleSend /> };

      case 'repeat':
        return { title: strings.menuActionRepeat, icon: <Repeat /> };

      case 'distribute':
        return { title: strings.menuActionDistribute, icon: <Expand /> };
    }
  };

  return (
    <ResponsiveDialog
      sx={sx}
      className={className}
      maxWidth="sm"
      fullWidth={true}
      open={true}
      maxHeight="large"
      onClose={closeModal}
      disableEnforceFocus
    >
      <DialogHeader
        title={headerTitle}
        subtitle={headerSubtitle}
        color={headerColor}
        tintColor={headerTintColor}
        onClose={closeModal}
        rightButton={
          viewModel.canEdit
            ? {
                icon: (size) => <Edit color="inherit" fontSize={size} />,
                action: didClickOnEditIcon,
                color: 'inherit'
              }
            : undefined
        }
      />
      <TaskInfoView sx={{ flex: 1 }} viewModel={viewModel} isRoot={true} />
      <Menu
        id="task-info-actions-menu"
        anchorEl={actionButtonRef.current}
        open={displayActionsMenu}
        slotProps={{ paper: { sx: { width: 300 } } }}
        onClose={closeActionMenu}
      >
        {menuItems.map((item) => {
          const action = taskInfoMenuAction(item);
          return (
            <MenuItem
              key={item}
              value={item}
              onClick={() => {
                void didPressOnMenuItem(item);
              }}
            >
              <ListItemIcon>{action.icon}</ListItemIcon>
              <ListItemText>{action.title}</ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
      <DialogActionButtons>
        {!viewModel.task.isSlave && menuItems.length > 0 && (
          <div ref={actionButtonRef}>
            <DialogButton
              title={localizationService.localizedStrings.studyo.contents.taskInfo.actionsButtonTitle}
              onPress={didClickOnActionButton}
              type="normal"
            />
          </div>
        )}

        <Box
          sx={{
            flex: 1
          }}
        />

        {viewModel.canToggleState && viewModel.task.state === 'active' && (
          <DialogButton title={strings.completeButtonText} type="success" onPress={didClickOnCompleteTask} />
        )}

        <DialogButton title={strings.close} type="normal" onPress={closeModal} minimumScreenSize="sm" />
      </DialogActionButtons>
      <ConfirmDialog
        open={displayDeleteConfirmationDialog}
        title={strings.deleteConfirmTitle}
        description={strings.deleteConfirmDescription}
        cancelTitle={strings.deleteConfirmStayTitle}
        onCancelPress={hideDeleteConfirmationDialog}
        confirmTitle={strings.deleteConfirmDiscardTitle}
        onConfirmPress={deleteTask}
        onClose={hideDeleteConfirmationDialog}
      />
      <ConfirmDialog
        open={displayLongTimeSpanDialog}
        title={strings.longTimeSpanAlertTitle}
        description={strings.longTimeSpanAlertDescription}
        cancelTitle={localizationService.localizedStrings.studyo.utils.alertDialogCancel}
        onCancelPress={hideLongTimeSpanDialog}
        confirmTitle={strings.longTimeSpanAlertPublish}
        onConfirmPress={longTimeSpanConfirm}
        onClose={hideLongTimeSpanDialog}
      />
      <SaveDialog
        promise={unpublishPromise}
        retryCall={retryUnpublish}
        onClose={unpublishDialogClose}
        titles={{
          saving: strings.unpublishingTitle,
          saved: strings.unpublishedTitle,
          error: strings.unpublishErrorTitle
        }}
        descriptions={{
          saving: strings.unpublishingDescription,
          saved: strings.unpublishedDescription,
          error: strings.unpublishErrorDescription,
          permanentError: strings.unpublishPermanentErrorDescription
        }}
      />
      <SaveDialog
        promise={publishPromise}
        retryCall={retryPublish}
        onClose={publishDialogClose}
        titles={{
          saving: strings.publishingTitle,
          saved: strings.publishedTitle,
          error: strings.publishErrorTitle
        }}
        descriptions={{
          saving: strings.publishingDescription,
          saved: strings.publishedDescription,
          error: strings.publishErrorDescription,
          permanentError: strings.publishPermanentErrorDescription
        }}
      />
    </ResponsiveDialog>
  );
});
