import { ContentDefinitionUtils, SectionUtils } from '@shared/components/utils';
import { SectionModel } from '@shared/models/config';
import {
  ContentAttachmentModel,
  ContentPublishTarget,
  EditableContentDefinition,
  EditableContentPublishTarget
} from '@shared/models/content';
import { Color, ContentWorkloadLevel, Integration, RootAdminRoles } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { DataLoader, DialogCancelled, OnDestroy, OnInit } from '@shared/services';
import { AccountData } from '@shared/services/stores';
import { verifyAndConfirmWorkloads } from '@studyo/utils';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import {
  AccountAutoSyncService,
  AccountService,
  AttachmentManager,
  ContentPasteboardStore,
  NavigationService,
  StudyoAnalyticsEventActions,
  StudyoAnalyticsService
} from '../../services';
import { TimelineContentState, TimelineUtils } from '../agenda';
import { AppAttachmentsListBoxViewModel, AttachmentsListBoxViewModel } from './AttachmentsListBoxViewModel';
import { AppTaskDueBoxViewModel, TaskDueBoxViewModel } from './TaskDueBoxViewModel';
import { AppTaskStepListViewModel, TaskStepListViewModel } from './TaskStepListViewModel';

export interface TaskInfoViewModel extends OnInit, OnDestroy {
  readonly attachments: ContentAttachmentModel[];
  readonly attachmentsListBoxViewModel: AttachmentsListBoxViewModel;
  readonly canEdit: boolean;
  readonly canToggleState: boolean;
  readonly canCopy: boolean;
  readonly canDelete: boolean;
  readonly canPublish: boolean;
  readonly color?: Color;
  readonly contentState: TimelineContentState;
  readonly data: DataLoader;
  readonly externalSource?: Integration;
  readonly externalLink?: string;
  readonly hasExternalLink: boolean;
  readonly isDeleted: boolean;
  readonly isLinkedToOtherTasks: boolean;
  readonly isPublishing: boolean;
  readonly isUnpublishing: boolean;
  readonly hasPublishError: boolean;
  readonly canShowPublishDetails: boolean;
  readonly hasLongTimeSpan: boolean;
  readonly hasSteps: boolean;
  readonly numberOfLinkedTasks: number;
  readonly section?: SectionModel;
  readonly sectionTitle?: string;
  readonly sectionNumber?: string;
  readonly showAssessmentPlanningMessage: boolean;
  readonly showNoLoadMessage: boolean;
  readonly showIsPrivateMessage: boolean;
  readonly task: EditableContentDefinition;
  readonly taskDueBoxViewModel: TaskDueBoxViewModel;
  readonly title: string;
  readonly userStepsViewModel: TaskStepListViewModel;
  readonly workloadLevel: ContentWorkloadLevel;

  dismiss: () => void;
  editContent: () => Promise<void | DialogCancelled>;
  toggleState: () => void;
  showLinkedTasksDelete: () => Promise<void | DialogCancelled>;
  toggleDeleteState: () => void;
  publishTo: () => Promise<void | DialogCancelled>;
  publishToGroup: () => Promise<void | DialogCancelled>;
  unpublish: () => Promise<void>;
  copy: () => void;
  repeat: () => Promise<void | DialogCancelled>;
  distribute: () => Promise<void | DialogCancelled>;
  unlink: () => void;
  openExternalContentSource: () => void;
}

export class AppTaskInfoViewModel implements TaskInfoViewModel {
  readonly data: AccountData;
  @observable private _task: EditableContentDefinition;

  constructor(
    private readonly _accountService: AccountService,
    private readonly _attachmentManager: AttachmentManager,
    private readonly _contentPasteboard: ContentPasteboardStore,
    private readonly _localizationService: LocalizationService,
    private readonly _navigationService: NavigationService,
    private readonly _analyticsService: StudyoAnalyticsService,
    private readonly _accountAutoSyncService: AccountAutoSyncService,
    private readonly _contentId: string,
    private readonly _onSuccess: () => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
    this.data = _accountService.displayedAccountData;
    this._task = new EditableContentDefinition(this.data.contentsById.get(this._contentId)!, false);
  }

  get task() {
    return this._task;
  }

  @computed
  private get linkedTasks() {
    if (this.task.contentsGroupIdentifier.length === 0) {
      return [];
    }

    return this.data.visibleContents.filter(
      (cd) => cd.id !== this.task.id && cd.contentsGroupIdentifier === this.task.contentsGroupIdentifier
    );
  }

  @computed
  get attachments() {
    if (this.task.isSlave && this.task.masterContent != null) {
      return this.task.masterContent.attachmentsList;
    } else {
      return this.task.attachments;
    }
  }

  @computed
  get attachmentsListBoxViewModel() {
    return new AppAttachmentsListBoxViewModel(
      this._accountService,
      this._navigationService,
      this.task,
      !this.data.isReadOnly
    );
  }

  @computed
  get canEdit() {
    return !this.data.isReadOnly && (this.task.externalContent == null || this.task.isSlave);
  }

  @computed
  get canCopy() {
    return !this.data.isImpersonating && this.task.externalContent == null;
  }

  @computed
  get canToggleState() {
    return !(
      this.data.isImpersonating ||
      this.task.state === 'cancelled' ||
      // We don't want to allow autocompleted tasks to toggle state
      (this.task.state === 'completed' && ContentDefinitionUtils.isNoLoadAndPastDue(this.task))
    );
  }

  @computed
  get canDelete() {
    return this.canEdit && !this.task.isSlave;
  }

  @computed
  get contentState() {
    return TimelineUtils.getTimelineStateForContent(this.task);
  }

  @computed
  get color() {
    return SectionUtils.getSectionColor(this.section, this.data.account, undefined);
  }

  @computed
  get hasSteps() {
    return this.task.steps.length > 0;
  }

  @computed
  get section(): SectionModel | undefined {
    return this.data.sectionsById.get(this.task.sectionId);
  }

  @computed
  get sectionTitle() {
    if (this.section != null) {
      return this.section.title;
    } else {
      return undefined;
    }
  }

  @computed
  get sectionNumber() {
    if (this.section != null && this.section.sectionNumber.length > 0) {
      return this.section.sectionNumber;
    }

    return undefined;
  }

  @computed
  get showAssessmentPlanningMessage() {
    if (this.data.account.role === 'teacher') {
      return ContentDefinitionUtils.shouldBeFilteredByAssessmentPlanningForRole(
        this.task,
        'student',
        this.data.sectionsById,
        this.data.config
      );
    } else {
      return false;
    }
  }

  @computed
  get showNoLoadMessage() {
    return this.task.workloadLevel === 'none';
  }

  get showIsPrivateMessage() {
    return this.task.isPrivate;
  }

  @computed
  get taskDueBoxViewModel() {
    return new AppTaskDueBoxViewModel(this.data, this.task, this._localizationService);
  }

  @computed
  get userStepsViewModel() {
    return new AppTaskStepListViewModel(
      this.data.schoolDays,
      this.task,
      false,
      () => void this.data.createOrUpdateContent(this.task),
      false,
      !this.data.isReadOnly,
      false
    );
  }

  @computed
  get title() {
    return ContentDefinitionUtils.getDisplayTitleForContent(this.task, this._localizationService.localizedStrings);
  }

  @computed
  get canPublish() {
    return this.canEdit && (this.section?.teacherIds.includes(this.data.accountId) ?? false);
  }

  @computed
  get isDeleted() {
    return this.task.state === 'cancelled';
  }

  @computed
  get isLinkedToOtherTasks() {
    if (this.task.contentsGroupIdentifier.length === 0) {
      return false;
    }

    return this.linkedTasks.length > 0;
  }

  @computed
  get isPublishing() {
    return this.task.publishTarget?.status === 'publishing' && this.task.isPublished;
  }

  @computed
  get isUnpublishing() {
    return this.task.publishTarget?.status === 'publishing' && !this.task.isPublished;
  }

  @computed
  get hasPublishError() {
    return this.task.publishTarget?.status === 'publish-error';
  }

  @computed
  get canShowPublishDetails() {
    return (
      this.task.publishTarget != null &&
      (this._accountService.isAllowed(RootAdminRoles) || window.STUDYO_ENV.environmentName == 'beta')
    );
  }

  @computed
  get numberOfLinkedTasks() {
    if (this.task.contentsGroupIdentifier.length === 0 || this.linkedTasks.length === 0) {
      // If we're alone in our group, act as if not in a group.
      return 0;
    }

    // We must include ourself in that count.
    return this.linkedTasks.length + 1;
  }

  @computed
  get externalSource() {
    return this.task.externalContent?.sourceIntegration;
  }

  @computed
  get externalLink() {
    return this.task.externalContent?.uri;
  }

  @computed
  get hasExternalLink(): boolean {
    const externalLink = this.task.externalContent?.uri;
    return externalLink != null && externalLink.length > 0;
  }

  @computed
  get hasLongTimeSpan() {
    if (ContentDefinitionUtils.hasLongTimeSpan(this.task, this.data)) {
      return true;
    }

    return this.linkedTasks.find((t) => ContentDefinitionUtils.hasLongTimeSpan(t, this.data)) != null;
  }

  @computed
  get workloadLevel() {
    return this.task.workloadLevel;
  }

  onInit() {
    this._accountAutoSyncService.suspend();
  }

  onDestroy() {
    this._accountAutoSyncService.resume();
  }

  dismiss() {
    this._onSuccess();
  }

  async editContent() {
    const response = await this._navigationService.navigateToTaskEditModal(
      this._contentId,
      undefined,
      undefined,
      undefined
    );

    if (response !== 'cancelled' && this._onSuccess != null) {
      this._onSuccess();
    }
  }

  toggleState() {
    this.task.toggleState();

    if (this.task.state === 'active') {
      this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.markAsNotDone });
    } else if (this.task.state === 'completed') {
      this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.markAsDone });
      this.dismiss();
    }

    void this.data.createOrUpdateContent(this.task);
  }

  toggleDeleteState() {
    this.task.toggleDeleteState();

    if (this.task.state === 'cancelled') {
      this.dismiss();
    }

    void this.data.createOrUpdateContent(this.task);
  }

  showLinkedTasksDelete() {
    return this._navigationService.navigateToLinkedTasksDelete(this.task.id);
  }

  async publishTo() {
    let result: void | DialogCancelled;

    if (this.isLinkedToOtherTasks) {
      result = await this._navigationService.navigateToLinkedTasksPublish(this._contentId);
    } else {
      result = await this._navigationService.navigateToContentPublishStudentSelectionModal(this._contentId);
    }

    if (result !== 'cancelled') {
      this._onSuccess();
    }

    return result;
  }

  async publishToGroup() {
    const tasksToPublish = this.data.visibleContents.filter(
      (cd) =>
        (cd.id === this.task.id ||
          (this.task.contentsGroupIdentifier.length > 0 &&
            cd.contentsGroupIdentifier === this.task.contentsGroupIdentifier)) &&
        cd.sectionId.length > 0
    );

    if (!(await verifyAndConfirmWorkloads(this._navigationService, this.data, tasksToPublish, false))) {
      return 'cancelled';
    }

    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.publishToGroup });

    await Promise.all(
      tasksToPublish.map(async (t) => {
        const task = new EditableContentDefinition(t, false);

        this.createPublishTargetIfNeeded(task);
        task.publishTarget!.kind = 'section';
        task.publishTarget!.targetAccountIds = [];
        await this.data.publishContent(task);
      })
    );
  }

  async unpublish() {
    const tasksToPublish = this.data.visibleContents.filter(
      (cd) =>
        (cd.id === this.task.id ||
          (this.task.contentsGroupIdentifier.length > 0 &&
            cd.contentsGroupIdentifier === this.task.contentsGroupIdentifier)) &&
        cd.sectionId.length > 0
    );

    this._analyticsService.trackEvent({ action: StudyoAnalyticsEventActions.task.unpublish });

    await Promise.all(
      tasksToPublish.map(async (t) => {
        const task = new EditableContentDefinition(t, false);

        this.createPublishTargetIfNeeded(task);
        task.publishTarget!.kind = 'accounts';
        task.publishTarget!.targetAccountIds = [];
        await this.data.publishContent(task);
      })
    );
  }

  copy() {
    this._contentPasteboard.storedContent = this.task;
  }

  async repeat() {
    const result = await this._navigationService.navigateToContentRepeat(this._contentId);

    if (result !== 'cancelled') {
      runInAction(() => (this._task = new EditableContentDefinition(result, false)));
    }
  }

  async distribute() {
    const result = await this._navigationService.navigateToContentDistribute(this._contentId);

    if (result !== 'cancelled') {
      this._onSuccess();
    }
  }

  unlink() {
    this.task.contentsGroupIdentifier = '';
    void this.data.createOrUpdateContent(this.task);
    this._onSuccess();
  }

  openExternalContentSource() {
    if (this.task.externalContent != null) {
      this._attachmentManager.openUrl(this.task.externalContent.uri);
    }
  }

  private createPublishTargetIfNeeded(task: EditableContentDefinition) {
    if (task.publishTarget == null) {
      task.publishTarget = new EditableContentPublishTarget(ContentPublishTarget.createNew(), true);
    }
  }
}
