export interface SectionMetricViewsStrings {
  addStudentsFromIds: string;
  addStudentsFromIdsConfirm: string;
  announced: string;
  announcedTooltip: string;
  cancel: string;
  close: string;
  csvExportTooltip: string;
  delete: string;
  done: string;
  doneTooltip: string;
  editSectionSchedules: string;
  editSectionStudents: string;
  emailTitle: string;
  grade: string;
  group: string;
  importFromSection: string;
  importFromSectionConfirm: string;
  loadingDataMessage: string;
  loadingDataErrorMessage: string;
  loadingDetailsErrorMessage: string;
  loadingDetailsMessage: string;
  loadingSectionsMessage: string;
  loadingSectionsErrorMessage: string;
  manageSectionConflictsTitle: string;
  name: string;
  noName: string;
  noScheduleMessage: string;
  noSectionTitle: string;
  noDefaultTeacher: string;
  planned: string;
  plannedTooltip: string;
  publishedTasks: string;
  room: string;
  save: string;
  searchSectionImport: string;
  section: string;
  sectionNotOccurPublished: string;
  sectionOccursNoPublished: string;
  sectionOccursPublished: string;
  sections: string;
  sectionSelection: string;
  showLegend: string;
  skipAndMoveMenu: string;
  skipConflictingPeriodsTooltip: string;
  skipDayPeriodsTooltip: string;
  skipNoMoveMenu: string;
  skipMenuTitle: string;
  steps: string;
  stepsTooltip: string;
  showPublishedTasks: string;
  student: string;
  studentIdsLabel: string;
  studentsTitle: string;
  studentsWithoutTasks: string;
  studentsWithOwnTasks: string;
  tasks: string;
  teacherPlanningTitle: string;

  createOrEditSection: (count: number) => string;
  editSectionTeachers: (sectionTitle?: string, teacherName?: string) => string;
  localizedNumberOfStudents: (count: number) => string;
}

export const EnglishSectionMetricViewsStrings: SectionMetricViewsStrings = {
  addStudentsFromIds: 'Add from ids…',
  addStudentsFromIdsConfirm: 'Add',
  announced: 'Announced',
  announcedTooltip: 'Indicates that the announcement date is different from the due date.',
  cancel: 'Cancel',
  close: 'Close',
  csvExportTooltip: 'Export all students tasks from this section for this school year to CSV',
  delete: 'Delete',
  done: 'Done',
  doneTooltip: 'Indicates that the student has marked the task as done.',
  editSectionSchedules: 'Edit Section Schedules',
  editSectionStudents: 'Edit Section Students',
  emailTitle: 'Email',
  grade: 'Grade',
  group: 'Group',
  importFromSection: 'Add from other section…',
  importFromSectionConfirm: 'Add',
  loadingDataMessage: 'Loading data… please hang on!',
  loadingDataErrorMessage: 'An error occurred while loading the data. Please reload.',
  loadingDetailsErrorMessage: 'An error occurred while loading the section details',
  loadingDetailsMessage: 'Loading section details. Please wait…',
  loadingSectionsMessage: 'Loading sections. Please wait…',
  loadingSectionsErrorMessage: 'An error occurred while loading the sections. Please reload.',
  manageSectionConflictsTitle: 'Manage schedule conflicts',
  name: 'Name',
  noName: '(no name)',
  noScheduleMessage:
    "Since this section doesn't contain any schedule, an empty one will be added to store the teachers.",
  noSectionTitle: '[no title]',
  noDefaultTeacher: '[no default teacher]',
  planned: 'Planned',
  plannedTooltip: 'Indicates that the student has set a planned date.',
  publishedTasks: 'Published tasks:',
  room: 'Room',
  save: 'Save',
  searchSectionImport: 'Search',
  section: 'Section',
  sectionNotOccurPublished: 'Section does not occur - Published tasks',
  sectionOccursNoPublished: 'Section occurs - No published tasks',
  sectionOccursPublished: 'Section occurs - Published tasks',
  sections: 'Sections',
  sectionSelection: 'Select Section…',
  showLegend: 'Show legend…',
  skipAndMoveMenu: 'Skip class occurrence and shift tasks and notes to the next occurrence until the end',
  skipConflictingPeriodsTooltip: 'Skip all class occurrences that conflict with this class.',
  skipDayPeriodsTooltip: 'Skip all class occurrences for the whole day',
  skipNoMoveMenu: 'Skip class occurrence without affecting tasks and notes',
  skipMenuTitle: "Skipping a class occurrence: effect on teachers' tasks and notes",
  steps: 'Steps',
  stepsTooltip: 'Indicates that the student has divided the task into steps.',
  showPublishedTasks: 'Show published tasks',
  student: 'Student',
  studentIdsLabel: 'Student Ids',
  studentsTitle: 'Students',
  studentsWithoutTasks: 'Students without own tasks:',
  studentsWithOwnTasks: 'Students with own tasks:',
  tasks: 'Tasks',
  teacherPlanningTitle: 'Manage Teacher Schedule',

  localizedNumberOfStudents: englishLocalizedNumberOfStudents,
  editSectionTeachers: englishEditSectionTeachers,
  createOrEditSection: englishCreateOrEditSection
};

export const FrenchSectionMetricViewsStrings: SectionMetricViewsStrings = {
  addStudentsFromIds: 'Ajouter par identifiants…',
  addStudentsFromIdsConfirm: 'Ajouter',
  announced: 'Annoncée',
  announcedTooltip: 'Indique qu’une date d’annonce différente de l’échéance est indiquée.',
  cancel: 'Annuler',
  close: 'Fermer',
  csvExportTooltip:
    'Exporter les tâches de tous les élèves pour ce groupe-matière pour toute l’année scolaire dans un fichier CSV',
  delete: 'Effacer',
  done: 'Fait',
  doneTooltip: 'Indique que l’élève a coché la tâche.',
  editSectionSchedules: 'Modif. horaires de groupe-matière',
  editSectionStudents: 'Modif. évèves de groupe-matière',
  emailTitle: 'Courriel',
  grade: 'Niveau',
  group: 'Groupe',
  importFromSection: 'Ajouter d’un autre groupe-matière…',
  importFromSectionConfirm: 'Ajouter',
  loadingDataMessage: 'Chargement des données… Merci de patienter',
  loadingDataErrorMessage: 'Une erreur est survenue lors du chargement. Merci de recharger.',
  loadingDetailsErrorMessage: 'Une erreur est survenue lors du chargement des détails du groupe-matière',
  loadingDetailsMessage: 'Chargement des détails du groupe-matière. Merci de patienter…',
  loadingSectionsMessage: 'Chargement des groupes-matière. Merci de patienter…',
  loadingSectionsErrorMessage: 'Une erreur est survenue lors du chargement des groupes-matière. Merci de recharger.',
  manageSectionConflictsTitle: 'Gérer les conflits d’horaire',
  name: 'Nom',
  noName: '(pas de nom)',
  noScheduleMessage:
    'Puisque ce groupe-matière ne contient aucune cédule, une cédule vide sera ajoutée pour y inscrire les enseignants.',
  noSectionTitle: '[Pas de titre]',
  noDefaultTeacher: '[Pas d’enseignant par défaut]',
  planned: 'Planifiée',
  plannedTooltip: 'Indique que l’élève a ajusté la date de planification.',
  publishedTasks: 'Tâches publiées:',
  room: 'Local',
  save: 'Enregistrer',
  searchSectionImport: 'Rechercher',
  section: 'Groupe-matière',
  sectionNotOccurPublished: 'Pas de cours - Tâches publiées',
  sectionOccursNoPublished: 'Période de cours - Pas de tâche publiée',
  sectionOccursPublished: 'Période de cours - tâches publiées',
  sections: 'Groupes-matière',
  sectionSelection: 'Choisir un groupe-matière…',
  showLegend: 'Afficher la légende…',
  skipAndMoveMenu: 'Sauter le cours et déplacer les tâches et notes aux prochains cours respectifs',
  skipConflictingPeriodsTooltip: 'Sauter tous les cours en conflit avec ce cours',
  skipDayPeriodsTooltip: 'Sauter tous les cours de la journée',
  skipNoMoveMenu: 'Sauter le cours sans déplacer les tâches ni les notes',
  skipMenuTitle: 'Sauter un cours: effet sur les tâches et notes des enseignants',
  steps: 'Étapes',
  stepsTooltip: 'Indique que l’élève a ajouté des étapes.',
  showPublishedTasks: 'Afficher les tâches publiées',
  student: 'Élève',
  studentIdsLabel: 'Identifiants d’élève',
  studentsTitle: 'Élèves',
  studentsWithoutTasks: 'Élèves sans tâches personnelles:',
  studentsWithOwnTasks: 'Élèves avec tâches personnelles:',
  tasks: 'Tâches',
  teacherPlanningTitle: 'Gérer l’horaire d’un enseignant',

  localizedNumberOfStudents: LocalizedNumberOfStudentsStudentsWithCount,
  editSectionTeachers: frenchEditSectionTeachers,
  createOrEditSection: frenchCreateOrEditSection
};

function englishCreateOrEditSection(count: number) {
  if (count === 0) {
    return 'Create Section';
  }
  return count === 1 ? 'Edit Section' : 'Edit Sections';
}

function frenchCreateOrEditSection(count: number) {
  if (count === 0) {
    return 'Créer groupe-matière';
  }
  return count === 1 ? 'Modifier groupe-matière' : 'Modifier groupes-matière';
}

function englishLocalizedNumberOfStudents(count: number) {
  return count === 1 ? count + ' student' : count + ' students';
}

function LocalizedNumberOfStudentsStudentsWithCount(count: number) {
  return count === 1 ? count + ' élève' : count + ' élèves';
}

function englishEditSectionTeachers(sectionTitle?: string, teacherName?: string) {
  let baseTitle = 'Edit Section Teachers';
  if (sectionTitle != null) {
    baseTitle += ` - ${sectionTitle}`;
  }
  if (teacherName != null) {
    baseTitle += ` | ${teacherName}`;
  }
  return baseTitle;
}

function frenchEditSectionTeachers(sectionTitle?: string, teacherName?: string) {
  let baseTitle = 'Modif. enseignants de groupe-matière';
  if (sectionTitle != null) {
    baseTitle += ` - ${sectionTitle}`;
  }
  if (teacherName != null) {
    baseTitle += ` | ${teacherName}`;
  }
  return baseTitle;
}
