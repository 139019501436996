import { AccountSummary } from '@shared/models/config/AccountSummary';
import { AccountService, NavigationService } from '@studyo/services';
import { computed, makeObservable } from 'mobx';
import { AppProfileMenuProfileViewModel, ProfileMenuProfileViewModel } from './ProfileMenuProfileViewModel';

export interface ProfileMenuActiveProfileViewModel extends ProfileMenuProfileViewModel {
  readonly canEditProfile: boolean;

  editProfile(): void;
}

export class AppProfileMenuActiveProfileViewModel
  extends AppProfileMenuProfileViewModel
  implements ProfileMenuActiveProfileViewModel
{
  constructor(
    accountSummary: AccountSummary | undefined,
    childAccountSummary: AccountSummary | undefined,
    accountService: AccountService,
    navigationService: NavigationService,
    onSwitchProfile: () => void
  ) {
    super(accountSummary, childAccountSummary, accountService, navigationService, onSwitchProfile);
    makeObservable(this);
  }

  @computed
  get canEditProfile(): boolean {
    return (this._accountService.currentAccount as AccountSummary) != null && this._childAccountSummary == null;
  }

  editProfile() {
    void this._navigationService.navigateToProfileEdit();
  }
}
