import { Account, AccountModel, AccountSummary } from './config';
import { AuthorizationRole, Role } from './types';

export const AccountRoleComparer = (
  a: Account | AccountSummary | AccountModel,
  b: Account | AccountSummary | AccountModel
): number => {
  return RoleComparer(a.role, b.role);
};

export const RoleComparer = (a: Role, b: Role): number => {
  if (a === b) {
    return 0;
  }

  return getRoleWeight(b) - getRoleWeight(a);
};

export const AuthorizationRoleComparer = (a: AuthorizationRole, b: AuthorizationRole): number => {
  if (a === b) {
    return 0;
  }

  return getAuthorizationRoleWeight(b) - getAuthorizationRoleWeight(a);
};

export const getRoleWeight = (role: Role): number => {
  switch (role) {
    case 'studyo-staff':
      return 5;
    case 'school-staff':
      return 4;
    case 'teacher':
      return 3;
    case 'student':
      return 2;
    case 'parent':
      return 1;
    default:
      return 0;
  }
};

export const getAuthorizationRoleWeight = (role: AuthorizationRole): number => {
  switch (role) {
    case 'super-admin':
      return 8;
    case 'admin':
      return 7;
    case 'super-observer':
      return 6;
    case 'studyo-staff':
      return 5;
    case 'school-staff':
      return 4;
    case 'teacher':
      return 3;
    case 'student':
      return 2;
    case 'parent':
      return 1;
    default:
      return 0;
  }
};
