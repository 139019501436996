// prettier-ignore
export type AuthorizationRole = 
  'super-admin' | 
  'super-observer' |
  'admin' | 
  'teacher' | 
  'student' | 
  'parent' | 
  'studyo-staff' |
  'school-staff' |
  'individual';

// prettier-ignore
export const RootAdminRoles: AuthorizationRole[] = [
  'super-admin'
];

// prettier-ignore
export const RootRoles: AuthorizationRole[] = [
  'super-admin', 
  'super-observer'
];

// prettier-ignore
export const StudyoRoles: AuthorizationRole[] = [
  'super-admin',
  'super-observer',
  'studyo-staff'
];

// prettier-ignore
export const StudyoStaffOrRootAdminRoles: AuthorizationRole[] = [
  'super-admin',
  'studyo-staff'
];

// prettier-ignore
export const AllAuthorizationRoles: AuthorizationRole[] = [
  'super-admin',
  'super-observer',
  'admin',
  'teacher',
  'school-staff',
  'studyo-staff',
  'student',
  'parent'
];

// prettier-ignore
export const EmployeeAuthorizationRoles: AuthorizationRole[] = [
  'super-admin',
  'super-observer',
  'admin',
  'teacher',
  'school-staff',
  'studyo-staff'
];

// prettier-ignore
export const AdminAuthorizationRoles: AuthorizationRole[] = [
  'super-admin',
  'admin'
];

// prettier-ignore
export const AdminOrRootAuthorizationRoles: AuthorizationRole[] = [
  'super-admin',
  'super-observer',
  'admin'
];

// prettier-ignore
export const AdminOrTeacherAuthorizationRoles: AuthorizationRole[] = [
  'super-admin',
  'super-observer',
  'admin',
  'teacher'
];

// prettier-ignore
export const AdminOrTeacherNonObserverAuthorizationRoles: AuthorizationRole[] = [
  'super-admin',
  'admin',
  'teacher'
];

// prettier-ignore
export const AdminOrStaffAuthorizationRoles: AuthorizationRole[] = [
  'super-admin',
  'super-observer',
  'admin',
  'school-staff', 
  'studyo-staff'
];
