import {
  ExternalAccountKind as BufExternalAccountKind,
  ExternalGradingCondition
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import {
  CanvasGradingCondition,
  ClassroomGradingCondition,
  ExternalAccountKind,
  Integration
} from '@shared/models/types';
import { externalAccountKindFromProtobuf } from '@shared/models/types/EnumConversion';

export interface ConnectorComponentsStrings {
  accessToken: string;
  account: string;
  accountName: string;
  add: string;
  advancedTitle: string;
  applyToAll: string;
  assignmentTypeNameTitle: string;
  authenticationToken: string;
  autoMatchButton: string;
  blackbaudIncludeNonLeadSections: string;
  blackbaudNonLeadSectionsWarning: string;
  blackbaudPortalAddress: string;
  blackbaudSkyAuthorizationNotice: string;
  blackbaudSkyAuthorize: string;
  blackbaudSkyAuthorized: string;
  blackbaudSkyBeforeNotice: string;
  blackbaudSkyIgnoringUnmappedTypesLabel: string;
  blackbaudSkyIgnoringUnmappedTypesNotice: string;
  blackbaudSkyNotAuthorized: string;
  blackbaudSkyNotIgnoringUnmappedTypesNotice: string;
  blackbaudSkyRepeatAuthorization: string;
  cancel: string;
  canvasAssignmentGroupMappingsTitle: string;
  canvasAssignmentGroupTitle: string;
  canvasGradingConditionTitle: string;
  canvasIgnoringUnmappedGroupsLabel: string;
  canvasIgnoringUnmappedGroupsNotice: string;
  canvasNotIgnoringUnmappedGroupsNotice: string;
  classroomGradingConditionTitle: string;
  classroomIgnoringUnmatchedCourseWorkLabel: string;
  classroomIgnoringUnmatchedCourseWorkNotice: string;
  classroomIncludeCourseAliasesLabel: string;
  classroomNotIgnoringUnmatchedCourseWorkNotice: string;
  classroomMatchingExpressionLabel: string;
  classroomMatchingExpressionNotice: string;
  classroomMatchingValueTitle: string;
  classroomRemoveMatchedKeywordLabel: string;
  clientId: string;
  clientSecret: string;
  confirmForceRefresh: string;
  connectedAs: string;
  continue: string;
  consumerKey: string;
  consumerSecret: string;
  deleteAccount: string;
  defaultMaxDate: string;
  defaultMinDate: string;
  detachTooltip: string;
  discard: string;
  editAccount: string;
  editMappings: string;
  editSettings: string;
  email: string;
  enableIntegrations: string;
  errorNotificationSettingsButton: string;
  externalAccountListTitle: string;
  externalContentAccountKind: (kind: BufExternalAccountKind) => string;
  externalContentAccountKindsLabel: string;
  externalContentAdvancedOptionsSubtitle: string;
  externalContentKeywordMappingKindLabel: string;
  externalContentKeywordMappingKindCategoryLabel: string;
  externalContentKeywordMappingKindTitleLabel: string;
  externalContentMappingsTooltip: string;
  externalContentOptionsSubtitle: string;
  externalGradingCondition: (condition: ExternalGradingCondition) => string;
  externalSection: string;
  externalSectionCode: string;
  externalSectionExtraData: string;
  externalSectionGroup: string;
  externalSectionId: string;
  externalSectionLevel: string;
  externalSectionTerm: string;
  externalSectionTitle: string;
  failedAssociationsTooltip: string;
  filtersTooltip: string;
  forgetAboutAllTasks: string;
  forgetAboutAssociationTasks: string;
  generalSectionTitle: string;
  googleAlreadyConnectedHeader: string;
  googleAlreadyConnectedMessage: string;
  googleAuthorizeButton: string;
  googleExistingAccount: (email: string) => string;
  googleInitializing: string;
  googleInstructions: string;
  googleMismatchedEmails: string;
  googleNoAccount: string;
  googleRedoConnectionButton: string;
  googleSignInButton: string;
  googleTitle: string;
  gradingConditionTitle: string;
  iconTitle: string;
  ignoreUnmatchedContentLabel: string;
  incompleteDataTooltip: string;
  keywordMappingExpressionLabel: string;
  keywordMappingExpressionNotice: string;
  keywordTitle: string;
  kind: string;
  kindNameTitle: string;
  lastUpdate: string;
  levelLabel: string;
  manageBacIgnoringUnmappedKindsLabel: string;
  manageBacIgnoringUnmappedKindsNotice: string;
  manageBacNotIgnoringUnmappedKindsNotice: string;
  manageBacTermIdsNotice: string;
  manageBacTermIdsTitle: string;
  microsoftTeamsAssignmentCategoryTitle: string;
  microsoftTeamsAuthorizationButton: string;
  microsoftTeamsAuthorizationExistingAccount: string;
  microsoftTeamsAuthorizationMessage: string;
  microsoftTeamsCustomClassesFilterLabel: string;
  microsoftTeamsIgnoringUnmappedCategoriesLabel: string;
  microsoftTeamsIgnoringUnmappedCategoriesNotice: string;
  microsoftTeamsNotIgnoringUnmappedCategoriesNotice: string;
  minMaxDates: string;
  name: string;
  noLevel: string;
  none: string;
  noSubAccount: string;
  noTerm: string;
  noTitle: string;
  notUpdatedYet: string;
  okButtonCaption: string;
  orphanAssociationTitle: string;
  owner: string;
  password: string;
  portalUrl: string;
  queueSync: string;
  queueSyncForceRefresh: string;
  removeMatchedKeywordLabel: string;
  requiredCourseCodePrefixLabel: string;
  save: string;
  saveToAll: string;
  savingChanges: string;
  scheduledAutoMatchButton: string;
  schoolRoute: string;
  schoolYear: string;
  searchSectionLabel: string;
  section: string;
  serverUrl: string;
  shouldDisplaySections: string;
  shouldDisplaySectionsNotice: string;
  skip: string;
  status: string;
  subAccountLabel: string;
  sync: string;
  syncForceRefresh: string;
  teacherLabel: string;
  termsLabel: string;
  testSync: string;
  throttleSettingsButton: string;
  unknownExternalSection: string;
  unmatchAllButton: string;
  username: string;
  viewAssociations: string;
  workloadLevelTitle: string;
  yearLabel: string;

  accountKindName(kind: ExternalAccountKind): string;
  localizedIntegrationName(integration: Integration): string;
  localizedAssociationsStatus(failed: number, total: number): string;
  localizedAddAccountTooltip(kind: ExternalAccountKind): string;
  localizedCanvasGradingCondition(condition: CanvasGradingCondition): string;
  localizedClassroomGradingCondition(condition: ClassroomGradingCondition): string;
}

function englishAccountKindName(kind: ExternalAccountKind): string {
  switch (kind) {
    case 'blackbaud':
      return 'Blackbaud OnCampus (legacy)';
    case 'blackbaud-sky':
      return 'Blackbaud';
    case 'calendars':
      return 'Calendars';
    case 'canvas':
      return 'Canvas';
    case 'google':
      return 'Google Classroom';
    case 'schoology':
      return 'Schoology';
    case 'veracross':
      return 'Veracross v2 (legacy)';
    case 'veracross-v3':
      return 'Veracross v3';
    case 'managebac':
      return 'ManageBac';
    case 'moodle':
      return 'Moodle';
    case 'studyo-internal':
      return 'Studyo Training';
    case 'microsoft-teams':
      return 'Microsoft Teams';
    case 'unknown':
      return 'Unknown';
    case 'any':
      return 'Any';
  }
}

function englishLocalizedIntegrationName(integration: Integration): string {
  switch (integration) {
    case 'blackbaud':
      return 'Blackbaud onCampus';
    case 'blackbaud-sky-connector':
      return 'Blackbaud Sky';
    case 'canvas':
      return 'Canvas';
    case 'google-classroom':
      return 'Google Calendars (legacy)';
    case 'google-classroom-connector':
      return 'Google Classroom';
    case 'gradeslam':
      return 'Gradeslam (legacy)';
    case 'ical':
      return 'iCal Calendars';
    case 'schoology-connector':
      return 'Schoology';
    case 'veracross':
      return 'Veracross iCal (legacy)';
    case 'veracross-connector':
      return 'Veracross v2 (legacy)';
    case 'veracross-v3-connector':
      return 'Veracross v3';
    case 'managebac-connector':
      return 'ManageBac';
    case 'microsoft-teams-connector':
      return 'Microsoft Teams';
    case 'moodle-connector':
      return 'Moodle';
    case 'studyo-internal':
      return 'Studyo Training';
    default:
      return `Unknown (${integration})`;
  }
}

function englishLocalizedAssociationsStatus(failed: number, total: number) {
  if (failed === 0) {
    return `OK (${total})`;
  } else {
    return `ERROR (${failed} / ${total})`;
  }
}

function englishLocalizedAddAccountTooltip(kind: ExternalAccountKind): string {
  switch (kind) {
    case 'blackbaud':
    case 'blackbaud-sky':
      return 'Add Blackbaud account';
    case 'calendars':
      return 'Add iCal Calendar account';
    case 'canvas':
      return 'Add Canvas account';
    case 'google':
      return 'Add Google Classroom account';
    case 'schoology':
      return 'Add Schoology account';
    case 'veracross':
    case 'veracross-v3':
      return 'Add Veracross account';
    case 'managebac':
      return 'Add ManageBac account';
    case 'microsoft-teams':
      return 'Add Microsoft Teams account';
    case 'moodle':
      return 'Add Moodle account';
    case 'studyo-internal':
      return 'Add Studyo Training account';
    default:
      return 'Add account';
  }
}

function englishLocalizedCanvasGradingCondition(condition: CanvasGradingCondition): string {
  switch (condition) {
    case 'any':
      return 'Any';
    case 'graded':
      return 'Graded';
    case 'practice':
      return 'Practice';
  }
}

function englishLocalizedClassroomGradingCondition(condition: ClassroomGradingCondition): string {
  switch (condition) {
    case 'any':
      return 'Any';
    case 'graded':
      return 'Graded';
    case 'not-graded':
      return 'Not Graded';
  }
}

function frenchAccountKindName(kind: ExternalAccountKind): string {
  switch (kind) {
    case 'blackbaud':
      return 'Blackbaud OnCampus (ancien)';
    case 'blackbaud-sky':
      return 'Blackbaud';
    case 'calendars':
      return 'Calendriers';
    case 'canvas':
      return 'Canvas';
    case 'google':
      return 'Google Classroom';
    case 'schoology':
      return 'Schoology';
    case 'veracross':
      return 'Veracross v2 (ancien)';
    case 'veracross-v3':
      return 'Veracross v3';
    case 'managebac':
      return 'ManageBac';
    case 'moodle':
      return 'Moodle';
    case 'studyo-internal':
      return 'Formation Studyo';
    case 'microsoft-teams':
      return 'Microsoft Teams';
    case 'unknown':
      return 'Inconnu';
    case 'any':
      return 'Tous';
  }
}

function frenchLocalizedIntegrationName(integration: Integration): string {
  switch (integration) {
    case 'blackbaud':
      return 'Blackbaud onCampus';
    case 'blackbaud-sky-connector':
      return 'Blackbaud Sky';
    case 'canvas':
      return 'Canvas';
    case 'google-classroom':
      return 'Classroom via calendriers Google (désuet)';
    case 'google-classroom-connector':
      return 'Google Classroom';
    case 'gradeslam':
      return 'Gradeslam (désuet)';
    case 'ical':
      return 'Calendriers iCal';
    case 'schoology-connector':
      return 'Schoology';
    case 'veracross':
      return 'Veracross iCal (désuet)';
    case 'veracross-connector':
      return 'Veracross v2 (ancien)';
    case 'veracross-v3-connector':
      return 'Veracross v3';
    case 'managebac-connector':
      return 'ManageBac';
    case 'microsoft-teams-connector':
      return 'Microsoft Teams';
    case 'moodle-connector':
      return 'Moodle';
    case 'studyo-internal':
      return 'Formation Studyo';
    default:
      return `Inconnue (${integration})`;
  }
}

function frenchLocalizedAssociationsStatus(failed: number, total: number) {
  if (failed === 0) {
    return `OK (${total})`;
  } else {
    return `ERREUR (${failed} / ${total})`;
  }
}

function frenchLocalizedAddAccountTooltip(kind: ExternalAccountKind): string {
  switch (kind) {
    case 'blackbaud':
    case 'blackbaud-sky':
      return 'Ajouter compte Blackbaud';
    case 'calendars':
      return 'Ajouter compte Calendrier iCal';
    case 'canvas':
      return 'Ajouter compte Canvas';
    case 'google':
      return 'Ajouter compte Google Classroom';
    case 'schoology':
      return 'Ajouter compte Schoology';
    case 'veracross':
    case 'veracross-v3':
      return 'Ajouter compte Veracross';
    case 'managebac':
      return 'Ajouter compte ManageBac';
    case 'microsoft-teams':
      return 'Ajouter compte Microsoft Teams';
    case 'moodle':
      return 'Ajouter compte Moodle';
    case 'studyo-internal':
      return 'Ajouter compte Formation Studyo';
    default:
      return 'Ajouter compte';
  }
}

function frenchLocalizedCanvasGradingCondition(condition: CanvasGradingCondition): string {
  // Au féminin, car on parle de "tâches".
  switch (condition) {
    case 'any':
      return 'Toutes';
    case 'graded':
      return 'Notées';
    case 'practice':
      return 'Pratique';
  }
}

function frenchLocalizedClassroomGradingCondition(condition: ClassroomGradingCondition): string {
  // Au masculin, car on parle de "devoir" dans Classroom
  switch (condition) {
    case 'any':
      return 'Tous';
    case 'graded':
      return 'Notés';
    case 'not-graded':
      return 'Pas notés';
  }
}

export const EnglishConnectorComponentsStrings: ConnectorComponentsStrings = {
  accessToken: 'Access Token',
  account: 'account',
  accountName: 'Account Name',
  add: 'Add',
  advancedTitle: 'Advanced Settings',
  applyToAll: 'Apply to all!',
  assignmentTypeNameTitle: 'Assignment type',
  authenticationToken: 'Authentication Token',
  autoMatchButton: 'Auto-Match',
  blackbaudIncludeNonLeadSections: 'Include sections that are based on a lead section',
  blackbaudNonLeadSectionsWarning:
    'Enable this option only if your sections are divided in a way that prevents fetching assingments from the lead section.',
  blackbaudPortalAddress: 'Blackbaud portal address (for assignment links)',
  blackbaudSkyAuthorizationNotice:
    'Upon creating this new account, you will be redirected to Blackbaud to authorize read-only access for Studyo.',
  blackbaudSkyAuthorize: 'Authorize',
  blackbaudSkyAuthorized: 'Blackbaud Sky access is authorized',
  blackbaudSkyBeforeNotice:
    "If you haven't done this yet, connect Studyo to your environment, using the Blackbaud Marketplace link below:",
  blackbaudSkyIgnoringUnmappedTypesLabel: 'Ignore assignment types not mapped',
  blackbaudSkyIgnoringUnmappedTypesNotice:
    'Any assignment without an assignment type or with one not in this list will be ignored.',
  blackbaudSkyNotIgnoringUnmappedTypesNotice:
    "Any assignment without an assignment type or with one not in this list will be mapped to a major exam if it's marked as major, otherwise to a regular homework.",

  blackbaudSkyNotAuthorized: 'Blackbaud Sky access was not authorized yet.',
  blackbaudSkyRepeatAuthorization: 'Repeat authorization',
  cancel: 'Cancel',
  canvasAssignmentGroupMappingsTitle: 'Assignment groups',
  canvasAssignmentGroupTitle: 'Group name',
  canvasGradingConditionTitle: 'Grading',
  canvasIgnoringUnmappedGroupsLabel: 'Ignore groups not mapped',
  canvasIgnoringUnmappedGroupsNotice:
    'Any assignment without an assignment group or with one not in this list will be ignored.',
  canvasNotIgnoringUnmappedGroupsNotice:
    "Any assignment without an assignment group or with one not in this list will be mapped to an important exam if it's marked as a quiz, otherwise to a regular homework.",
  classroomGradingConditionTitle: 'GC Grading',
  classroomIgnoringUnmatchedCourseWorkLabel: 'Ignore classwork with no matching keyword',
  classroomIgnoringUnmatchedCourseWorkNotice:
    'Any classwork for which a keyword was not found in their title will be ignored.',
  classroomIncludeCourseAliasesLabel: 'Also fetch course aliases',
  classroomNotIgnoringUnmatchedCourseWorkNotice:
    'Any classwork for which a keyword was not found in their title will be mapped to a regular homework or survey icon.',
  classroomMatchingExpressionLabel: 'Regular expression to find keywords',
  classroomMatchingExpressionNotice:
    "Do not change this value if you don't know what you're doing. It must contain one group.",
  classroomMatchingValueTitle: 'GC Keyword to match',
  classroomRemoveMatchedKeywordLabel: 'Remove keywords from task titles',
  clientId: 'Client ID',
  clientSecret: 'Client Secret',
  confirmForceRefresh: 'Do you really want to refresh every existing task?',
  connectedAs: 'Connected as',
  continue: 'Continue',
  consumerKey: 'Consumer Key',
  consumerSecret: 'Consumer Secret',
  defaultMaxDate: 'Def. max date',
  defaultMinDate: 'Def. min date',
  deleteAccount: 'Delete Account',
  detachTooltip: 'Delete association',
  discard: 'Discard',
  editAccount: 'Edit Account',
  editMappings: 'Edit Task Mappings',
  editSettings: 'Edit Connector Settings',
  email: 'Email',
  enableIntegrations: 'Enable or disable integrations',
  errorNotificationSettingsButton: 'Snooze errors',
  externalAccountListTitle: 'External accounts',
  externalContentAccountKind: (kind) => englishAccountKindName(externalAccountKindFromProtobuf(kind)),
  externalContentAccountKindsLabel: 'Affected external accounts',
  externalContentAdvancedOptionsSubtitle: 'Advanced',
  externalContentKeywordMappingKindCategoryLabel: 'By category (when available)',
  externalContentKeywordMappingKindLabel: 'Keyword mapping mode',
  externalContentKeywordMappingKindTitleLabel: 'By keyword in the title',
  externalContentMappingsTooltip: 'Assignment mapping',
  externalContentOptionsSubtitle: 'Options',
  externalGradingCondition: (condition) => {
    switch (condition) {
      case ExternalGradingCondition.ANY:
        return 'Graded or not';
      case ExternalGradingCondition.GRADED:
        return 'Graded';
      case ExternalGradingCondition.NOT_GRADED:
        return 'Not graded';
    }
  },
  externalSection: 'External section',
  externalSectionCode: 'Code',
  externalSectionExtraData: 'Extra data',
  externalSectionGroup: 'Group',
  externalSectionId: 'Id',
  externalSectionLevel: 'Level',
  externalSectionTerm: 'Term',
  externalSectionTitle: 'Title',
  failedAssociationsTooltip: 'We could not synchronize all associations for this external account.',
  filtersTooltip: 'Filter by teacher or term',
  forgetAboutAllTasks: 'Forget about all known imported tasks (force refresh)',
  forgetAboutAssociationTasks: 'Forget about known imported tasks (force refresh)',
  generalSectionTitle: 'General',
  googleAlreadyConnectedHeader: 'Already Connected!',
  googleAlreadyConnectedMessage: 'This Google account is already connected successfully.',
  googleAuthorizeButton: 'Authorize with Google',
  googleExistingAccount: (email) =>
    `Email "${email}" is already linked. If you use the same email, you will create a duplicate. Instead, search for it in the list.`,
  googleInitializing: 'Loading Google services…',
  googleInstructions:
    "In order to let Studyo access your Google Classroom classes, you must authorize either with a Google Domain administrator account or a teacher's Google account.",
  googleMismatchedEmails: 'You must authorize the same user as the one connected.',
  googleNoAccount: 'This Google external account will not be linked to a teacher account.',
  googleRedoConnectionButton: 'Redo Connection',
  googleSignInButton: 'Sign In with Google',
  googleTitle: 'Authorize a Google account',
  gradingConditionTitle: 'Grading',
  iconTitle: 'Studyo Icon',
  ignoreUnmatchedContentLabel: 'Ignore assignments not matched by any keyword',
  incompleteDataTooltip:
    'We could not fetch classes for this external account. The connection options might have changed.',
  keywordMappingExpressionLabel: 'Regular expression for matching keywords',
  keywordMappingExpressionNotice:
    "Do not change this value if you don't know what you're doing. It must contain one group.",
  keywordTitle: 'Keyword',
  kind: 'Kind',
  kindNameTitle: 'Assignment kind',
  lastUpdate: 'Last update',
  levelLabel: 'School level',
  manageBacIgnoringUnmappedKindsLabel: 'Ignore kinds not mapped',
  manageBacIgnoringUnmappedKindsNotice: 'Any assignment of a kind not in this list will be ignored.',
  manageBacNotIgnoringUnmappedKindsNotice:
    'Any assignment of a kind not in this list will be mapped to a regular homework.',
  manageBacTermIdsNotice: 'When none are specified, courses from any term can be mapped to Studyo sections.',
  manageBacTermIdsTitle: 'Term Ids',
  microsoftTeamsAssignmentCategoryTitle: 'Assignment category',
  microsoftTeamsAuthorizationButton: 'Authorize Studyo to access Teams',
  microsoftTeamsAuthorizationExistingAccount:
    'This external account is already linked with a Teams tenant, but you can repeat the connection process to solve authorization issues.',
  microsoftTeamsAuthorizationMessage:
    'In order to allow Studyo to fetch data from your Microsoft Teams school, a Teams administrator from your school must click the button below and accept the request.',
  microsoftTeamsCustomClassesFilterLabel: 'Custom class filter (advanced)',
  microsoftTeamsIgnoringUnmappedCategoriesLabel: 'Ignore categories not mapped',
  microsoftTeamsIgnoringUnmappedCategoriesNotice:
    'Any assignment without an assignment category or with one not in this list will be ignored.',
  microsoftTeamsNotIgnoringUnmappedCategoriesNotice:
    "Any assignment without an assignment category or with one not in this list will be mapped to a major exam if it's graded, otherwise to a regular homework.",
  minMaxDates: 'Min and max dates',
  name: 'Name',
  noLevel: 'Any level',
  none: 'None',
  noSubAccount: 'No sub-account required',
  noTerm: 'None - Use global courses',
  noTitle: '[no title]',
  notUpdatedYet: 'Not yet synced',
  okButtonCaption: 'Ok',
  orphanAssociationTitle: 'Orphan association',
  owner: 'Owner',
  password: 'Password',
  portalUrl: 'Portal URL',
  queueSync: 'Queue sync',
  queueSyncForceRefresh: 'Queue sync (force refresh)',
  removeMatchedKeywordLabel: 'Remove keyword from the title',
  requiredCourseCodePrefixLabel: 'Only keep course codes starting with:',
  save: 'Save',
  saveToAll: 'Save to all accounts',
  savingChanges: 'Saving changes…',
  scheduledAutoMatchButton: 'Scheduled auto-match',
  schoolRoute: 'School API Route',
  schoolYear: 'School Year',
  searchSectionLabel: 'Search section',
  section: 'Section',
  serverUrl: 'Server URL',
  shouldDisplaySections: 'Include sections within courses',
  shouldDisplaySectionsNotice: 'When sections are included, viewing your associations can take more time to load.',
  skip: 'Skip',
  status: 'Status',
  subAccountLabel: 'Sub-account',
  sync: 'Sync now!',
  syncForceRefresh: 'Sync now! (force refresh)',
  teacherLabel: 'Teacher',
  termsLabel: 'Terms (multiple terms can be selected)',
  testSync: 'Test sync',
  throttleSettingsButton: 'Throttle calls to server',
  unknownExternalSection: '(unknown ext. section)',
  unmatchAllButton: 'Unmatch all',
  username: 'Username',
  viewAssociations: 'View Associations',
  workloadLevelTitle: 'Studyo Workload',
  yearLabel: 'School year',

  accountKindName: englishAccountKindName,
  localizedIntegrationName: englishLocalizedIntegrationName,
  localizedAssociationsStatus: englishLocalizedAssociationsStatus,
  localizedAddAccountTooltip: englishLocalizedAddAccountTooltip,
  localizedCanvasGradingCondition: englishLocalizedCanvasGradingCondition,
  localizedClassroomGradingCondition: englishLocalizedClassroomGradingCondition
};

export const FrenchConnectorComponentsStrings: ConnectorComponentsStrings = {
  accessToken: 'Jeton d’accès',
  account: 'compte',
  accountName: 'Nom du compte',
  add: 'Ajouter',
  advancedTitle: 'Options avancées',
  applyToAll: 'Appliquer à tous!',
  assignmentTypeNameTitle: 'Type d’assignation',
  authenticationToken: 'Jeton d’authentification',
  autoMatchButton: 'Auto-correspondance',
  blackbaudIncludeNonLeadSections: 'Inclure les groupes basés sur un groupe racine',
  blackbaudNonLeadSectionsWarning:
    'Activez uniquement cette option si vos groupes sont divisés d’une façon qui empêche d’obtenir tous les devoirs du groupe racine.',
  blackbaudPortalAddress: 'Adresse du portail Blackbaud (pour liens aux devoirs)',
  blackbaudSkyAuthorizationNotice:
    'Une fois le compte créé, vous serez redirigé vers Blackbaud pour autoriser Studyo à accéder à vos données en lecture.',
  blackbaudSkyAuthorize: 'Autoriser',
  blackbaudSkyAuthorized: 'L’accès à Blackbaud Sky est autorisé',
  blackbaudSkyBeforeNotice:
    'Si ce n’est déjà fait, connectez Studyo à votre environnement via le lien Blackbaud Marketplace suivant:',
  blackbaudSkyIgnoringUnmappedTypesLabel: 'Ignorer les types de devoirs non-assignées',
  blackbaudSkyIgnoringUnmappedTypesNotice:
    'Tout devoir sans un type d’assignation ou dont le type n’est pas dans cette liste sera ignoré.',
  blackbaudSkyNotAuthorized: 'L’accès à Blackbaud Sky n’a pas encore été autorisé.',
  blackbaudSkyNotIgnoringUnmappedTypesNotice:
    'Tout devoir sans un type d’assignation ou dont le type n’est pas dans cette liste sera créé comme un examen majeur s’il est marqué comme majeur, sinon comme un devoir régulier.',
  blackbaudSkyRepeatAuthorization: 'Refaire l’autorisation',
  cancel: 'Annuler',
  canvasAssignmentGroupMappingsTitle: 'Groupes d’assignation',
  canvasAssignmentGroupTitle: 'Nom du groupe',
  canvasGradingConditionTitle: 'Évaluation',
  canvasIgnoringUnmappedGroupsLabel: 'Ignorer les groupes non-assignés',
  canvasIgnoringUnmappedGroupsNotice:
    'Toute tâche sans un groupe d’assignation ou dont le groupe n’est pas dans cette liste sera ignorée.',
  canvasNotIgnoringUnmappedGroupsNotice:
    'Toute tâche sans un groupe d’assignation ou dont le groupe n’est pas dans cette liste sera créée comme un examen important si elle est marquée comme un quiz, sinon comme un devoir régulier.',
  classroomGradingConditionTitle: 'Évaluation GC',
  classroomIgnoringUnmatchedCourseWorkLabel: 'Ignorer les travaux sans mot-clé trouvé',
  classroomIgnoringUnmatchedCourseWorkNotice:
    'Tout travail pour lequel un mot-clé n’a pas été trouvé dans le titre sera ignoré.',
  classroomIncludeCourseAliasesLabel: 'Aussi obtenir les alias de cours',
  classroomNotIgnoringUnmatchedCourseWorkNotice:
    'Tout travail pour lequel un mot-clé n’a pas été trouvé dans le titre sera considéré comme un devoir ou un questionnaire d’importance régulière.',
  classroomMatchingExpressionLabel: 'Expression régulière pour trouver les mots-clés',
  classroomMatchingExpressionNotice:
    'Ne changez pas cette valeur si vous ne savez pas ce que vous faites. L’expression doit contenir un groupe.',
  classroomMatchingValueTitle: 'Mot-clé GC',
  classroomRemoveMatchedKeywordLabel: 'Retirer le mot-clé des titres de tâches',
  clientId: 'Identifiant client',
  clientSecret: 'Secret client',
  confirmForceRefresh: 'Désirez-vous vraiment forcer la mise-à-jour de toutes les tâches?',
  connectedAs: 'Connecté en tant que',
  continue: 'Continuer',
  consumerKey: 'Clé d’accès',
  consumerSecret: 'Secret partagé',
  deleteAccount: 'Supprimer le compte',
  defaultMaxDate: 'Date max déf.',
  defaultMinDate: 'Date min déf.',
  detachTooltip: 'Retirer l’association',
  discard: 'Annuler',
  editAccount: 'Modifier le compte',
  editMappings: 'Modifier les filtres de tâche',
  editSettings: 'Modifier les réglages',
  email: 'Courriel',
  enableIntegrations: 'Activer ou désactiver des intégrations',
  errorNotificationSettingsButton: 'Mettre les erreurs en veille',
  externalAccountListTitle: 'Comptes externes',
  externalContentAccountKind: (kind) => frenchAccountKindName(externalAccountKindFromProtobuf(kind)),
  externalContentAccountKindsLabel: 'Comptes externes à traiter',
  externalContentAdvancedOptionsSubtitle: 'Avancé',
  externalContentKeywordMappingKindCategoryLabel: 'Par catégorie (lorsque disponible)',
  externalContentKeywordMappingKindLabel: 'Mode de jumelage des mots-clés',
  externalContentKeywordMappingKindTitleLabel: 'Par mot-clé dans le titre',
  externalContentMappingsTooltip: 'Transformations des devoirs par mot-clé',
  externalContentOptionsSubtitle: 'Options',
  externalGradingCondition: (condition) => {
    switch (condition) {
      case ExternalGradingCondition.ANY:
        return 'Noté ou pas';
      case ExternalGradingCondition.GRADED:
        return 'Noté';
      case ExternalGradingCondition.NOT_GRADED:
        return 'Non-noté';
    }
  },
  externalSection: 'Groupe-matière externe',
  externalSectionCode: 'Code',
  externalSectionExtraData: 'Autres',
  externalSectionGroup: 'Groupe',
  externalSectionId: 'Id',
  externalSectionLevel: 'Niveau',
  externalSectionTerm: 'Semestre',
  externalSectionTitle: 'Titre',
  failedAssociationsTooltip: 'Nous n’avons pas pu synchroniser toutes les associations de ce compte externe.',
  filtersTooltip: 'Filtrer par enseignant ou semestre',
  forgetAboutAllTasks: 'Oublier toutes les tâches importées (forcer mise-à-jour)',
  forgetAboutAssociationTasks: 'Oublier les tâches importées (forcer mise-à-jour)',
  generalSectionTitle: 'Général',
  googleAlreadyConnectedHeader: 'Déjà connecté!',
  googleAlreadyConnectedMessage: 'Ce compte Google est déjà connecté avec succès.',
  googleAuthorizeButton: 'Autoriser avec Google',
  googleExistingAccount: (email) =>
    `Le courriel "${email}" est déjà lié. Si vous utilisez le même courriel, vous allez créer un doublon. Cherchez plutôt dans la liste.`,
  googleInitializing: 'Chargement des services Google…',
  googleInstructions:
    'Pour permettre à Studyo d’accéder à vos classes Google Classroom, vous devez autoriser un compte Google administratif ou le compte Google d’un enseignant.',
  googleMismatchedEmails: 'Vous devez autoriser le même usager que celui connecté.',
  googleNoAccount: 'Ce compte externe Google ne sera associé à aucune fiche d’enseignant.',
  googleRedoConnectionButton: 'Refaire la connexion',
  googleSignInButton: 'Se connecter avec Google',
  googleTitle: 'Se connecter à Google',
  gradingConditionTitle: 'Notation',
  iconTitle: 'Icône Studyo',
  ignoreUnmatchedContentLabel: 'Ignorer les travaux sans correspondance de mot-clé',
  incompleteDataTooltip:
    'Nous n’avons pu obtenir les classes pour ce compte externe. Vos paramètres de connection ont peut-être changé ?',
  keywordMappingExpressionLabel: 'Expression régulière pour trouver les mots-clés',
  keywordMappingExpressionNotice:
    'Ne changez pas cette valeur si vous ne savez pas ce que vous faites. L’expression doit contenir un groupe.',
  keywordTitle: 'Mot-clé',
  kind: 'Type',
  kindNameTitle: 'Type de tâche',
  lastUpdate: 'Dernière Màj',
  levelLabel: 'Niveau',
  manageBacIgnoringUnmappedKindsLabel: 'Ignorer les types de tâche non-assignés',
  manageBacIgnoringUnmappedKindsNotice: 'Toute tâche dont le type n’est pas dans cette liste sera ignorée.',
  manageBacNotIgnoringUnmappedKindsNotice:
    'Toute tâche dont le type n’est pas dans cette liste sera créée comme un devoir régulier.',
  manageBacTermIdsNotice:
    'Lorsqu’aucun ID de semestre n’est spécifié, les classes de n’importe quel semestre peuvent être associées aux sections de Studyo.',
  manageBacTermIdsTitle: 'IDs de semestre',
  microsoftTeamsAssignmentCategoryTitle: 'Catégorie de devoir',
  microsoftTeamsAuthorizationButton: 'Autoriser Studyo à accéder à Teams',
  microsoftTeamsAuthorizationExistingAccount:
    'Ce compte externe est déjà lié à une école Teams, mais vous pouvez répéter la connexion pour résoudre des problèmes d’autorisation.',
  microsoftTeamsAuthorizationMessage:
    'Pour permettre à Studyo d’obtenir de l’information de votre école Microsoft Teams, un administrateur Teams de votre école doit cliquer le bouton ici-bas et accepter la requête.',
  microsoftTeamsCustomClassesFilterLabel: 'Filtre de classe (avancé)',
  microsoftTeamsIgnoringUnmappedCategoriesLabel: 'Ignorer les catégories non-assignées',
  microsoftTeamsIgnoringUnmappedCategoriesNotice:
    'Tout devoir sans une catégorie d’assignation ou dont la catégorie n’est pas dans cette liste sera ignoré.',
  microsoftTeamsNotIgnoringUnmappedCategoriesNotice:
    'Tout devoir sans une catégorie d’assignation ou dont la catégorie n’est pas dans cette liste sera créé comme un examen majeur s’il est noté, sinon comme un devoir régulier.',
  minMaxDates: 'Dates min et max',
  name: 'Nom',
  noLevel: 'Tous les niveaux',
  none: 'Aucun',
  noSubAccount: 'Aucun sous-compte requis',
  noTerm: 'Aucun - Utiliser les cours globaux',
  noTitle: '[Sans titre]',
  notUpdatedYet: 'Pas encore synchronisée',
  okButtonCaption: 'Ok',
  orphanAssociationTitle: 'Association sans matière-groupe',
  owner: 'Propriétaire',
  password: 'Mot de passe',
  portalUrl: 'Adresse web du portail',
  queueSync: 'Pousser la synchronisation',
  queueSyncForceRefresh: 'Pousser la synchronisation (publication forcée)',
  removeMatchedKeywordLabel: 'Retirer le mot-clé du titre',
  requiredCourseCodePrefixLabel: 'Ne garder que les code de cours qui commencent par:',
  save: 'Enregistrer',
  saveToAll: 'Enregistrer à tous les comptes',
  savingChanges: 'Enreg. les changements…',
  scheduledAutoMatchButton: 'Auto-correspondance cédulée',
  schoolRoute: 'Route d’API',
  schoolYear: 'Année scolaire',
  searchSectionLabel: 'Chercher une matière',
  section: 'Groupe-mat.',
  serverUrl: 'URL du serveur',
  shouldDisplaySections: 'Inclure les sections avec les matières',
  shouldDisplaySectionsNotice:
    'Lorsque les sections sont incluses, le chargement de la liste de vos associations peut être plus longue.',
  skip: 'Sauter',
  status: 'État',
  subAccountLabel: 'Sous-compte',
  sync: 'Synchroniser maintenant!',
  syncForceRefresh: 'Synchroniser maintenant! (publication forcée)',
  teacherLabel: 'Enseignant',
  termsLabel: 'Semestres (plusieurs peuvent être sélectionnés)',
  testSync: 'Tester la synchronisation',
  throttleSettingsButton: 'Réduire les appels au serveur',
  unknownExternalSection: '(groupe-matière ext. inconnu)',
  unmatchAllButton: 'Tout détacher',
  username: 'Nom d’utilisateur',
  viewAssociations: 'Voir les associations',
  workloadLevelTitle: 'Charge Studyo',
  yearLabel: 'Année scolaire',

  accountKindName: frenchAccountKindName,
  localizedIntegrationName: frenchLocalizedIntegrationName,
  localizedAssociationsStatus: frenchLocalizedAssociationsStatus,
  localizedAddAccountTooltip: frenchLocalizedAddAccountTooltip,
  localizedCanvasGradingCondition: frenchLocalizedCanvasGradingCondition,
  localizedClassroomGradingCondition: frenchLocalizedClassroomGradingCondition
};
