import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { Locale } from '@shared/resources/services';
import { LoginStrings } from '@shared/resources/strings/studyo/onboarding/LoginStrings.ts';
import { useNavigateAsync } from '@shared/utils';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useStudyoServices } from '../UseStudyoServicesHook';
import { StudyoLogo } from '../components';
import { LoginViewModel } from '../viewmodels';

export interface LoginViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: LoginViewModel;
}

export const LoginView = observer((props: LoginViewProps) => {
  const { localizationService } = useStudyoServices();
  const location = useLocation();
  const navigate = useNavigateAsync();

  const { sx = [], className, viewModel } = props;
  const strings = localizationService.localizedStrings.studyo.onboarding.login;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  return (
    <Stack
      className={clsx(className, 'login-content')}
      spacing={2}
      sx={{
        flex: 1,
        alignItems: 'center',
        p: { xs: 2, sm: 3 },
        ...sx,
        overflowX: 'hidden',
        overflowY: 'auto'
      }}
    >
      <StudyoLogo style={{ height: isExtraSmallScreen ? 75 : 100 }} />
      <Stack
        spacing={1}
        sx={{
          my: 3,
          alignItems: 'center',
          px: 1
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: 'center'
          }}
        >
          {strings.welcome}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            textAlign: 'center'
          }}
        >
          {strings.userMustLogin}
        </Typography>
      </Stack>
      <Box
        sx={{
          width: '100%',
          px: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: { xs: 'stretch', sm: 'center' }
        }}
      >
        <Button
          variant="contained"
          color="primary"
          loading={viewModel.isSigningUp}
          onClick={() => void viewModel.signUp(location, navigate)}
          disabled={viewModel.isSigningUp || viewModel.isLoggingIn}
          sx={{ flex: 1, maxWidth: !isExtraSmallScreen ? 350 : undefined }}
        >
          {strings.signUp}
        </Button>
      </Box>
      {viewModel.showPrivacyAlert && (
        <Typography
          variant="subtitle2"
          sx={{
            my: 1,
            color: (theme) => theme.palette.error.main,
            textAlign: 'center'
          }}
        >
          {strings.privacyPolicyAlertText}
        </Typography>
      )}
      <FormControlLabel
        sx={{ maxWidth: 350 }}
        control={<Checkbox />}
        checked={viewModel.hasAcceptedTerms}
        onChange={(_, checked) => viewModel.setHasAcceptedTerms(checked)}
        label={
          <Typography
            component="span"
            sx={{
              fontSize: 12,
              color: theme.palette.text.secondary
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: strings.privacyPolicyConfirmMessage }} />
          </Typography>
        }
      />
      <Box
        sx={{
          width: '100%',
          px: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: { xs: 'stretch', sm: 'center' }
        }}
      >
        <Button
          variant="contained-grey"
          color="primary"
          onClick={() => void viewModel.login(location, navigate)}
          loading={viewModel.isLoggingIn}
          disabled={viewModel.isSigningUp || viewModel.isLoggingIn}
          sx={{ flex: 1, maxWidth: !isExtraSmallScreen ? 350 : undefined }}
        >
          {strings.login}
        </Button>
      </Box>
      {viewModel.authInitErrorMessage != null && (
        <Typography
          sx={{
            color: (theme) => theme.palette.error.main,
            textAlign: 'center'
          }}
        >
          {viewModel.authInitErrorMessage}
        </Typography>
      )}
      {viewModel.hasError && (
        <Typography
          sx={{
            color: (theme) => theme.palette.error.main,
            textAlign: 'center'
          }}
        >
          {strings.loginError}
        </Typography>
      )}
      <Button onClick={() => viewModel.changeLanguage()} sx={{ p: 1, m: 1 }} size="small">
        {getLanguageButtonTitle(localizationService.currentLocale, strings)}
      </Button>
    </Stack>
  );
});

function getLanguageButtonTitle(currentLocale: Locale, strings: LoginStrings): string {
  switch (currentLocale) {
    case 'en':
      return strings.french;

    case 'fr':
      return strings.english;
  }
}
