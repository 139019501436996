import { MoveCourseOccurrencesRequest_MoveDirection } from '@buf/studyo_studyo.bufbuild_es/studyo/services/schools_pb';
import * as Auth from '@buf/studyo_studyo.bufbuild_es/studyo/type_auth_pb';
import * as Canvas from '@buf/studyo_studyo.bufbuild_es/studyo/type_canvas_pb';
import * as Classroom from '@buf/studyo_studyo.bufbuild_es/studyo/type_classroom_pb';
import * as Config from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import * as Connector from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import * as Contents from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';
import * as Importer from '@buf/studyo_studyo.bufbuild_es/studyo/type_importer_pb';
import * as Metrics from '@buf/studyo_studyo.bufbuild_es/studyo/type_metrics_pb';
import * as Onboarding from '@buf/studyo_studyo.bufbuild_es/studyo/type_onboarding_pb';
import * as MainTypes from '@buf/studyo_studyo.bufbuild_es/studyo/type_pb';
import * as Subscriptions from '@buf/studyo_studyo.bufbuild_es/studyo/type_subscriptions_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { AuthorizationRole } from './AuthorizationRole';
import * as M from './Enums';

// IMPORTANT:
//
// Methods converting FROM protobuf must not throw an exception if new values can
// potentially be added server-side while this app is not updated, or is already
// running an older version (yes, this can happen for web apps).
//
// Also, conversions in the other way must NOT declare a default case. This way,
// if we add a new value to a local enum, the code won't compile, ensuring we do
// not forget about conversion in the other way.

export function cycleDayEffectFromProtobuf(
  pb: Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect
): M.CycleDayEffect {
  switch (pb) {
    case Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.PRESERVE:
      return 'preserve';
    case Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.SEQUENCE:
      return 'sequence';
    case Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.INSERT:
      return 'insert';
    case Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.RESET:
      return 'reset';
    case Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.EXCEPTION:
      return 'exception';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected CycleDayEffect enum value');
  }
}

export function protobufFromCycleDayEffect(
  effect: M.CycleDayEffect
): Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect {
  switch (effect) {
    case 'preserve':
      return Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.PRESERVE;
    case 'sequence':
      return Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.SEQUENCE;
    case 'insert':
      return Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.INSERT;
    case 'reset':
      return Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.RESET;
    case 'exception':
      return Config.SchoolYearConfiguration_SpecialDay_CycleDayEffect.EXCEPTION;
  }
}

export function configTypeFromProtobuf(pb: Config.SchoolYearConfiguration_ConfigType): M.ConfigType {
  switch (pb) {
    case Config.SchoolYearConfiguration_ConfigType.STANDALONE:
      return 'standalone';
    case Config.SchoolYearConfiguration_ConfigType.MANAGED:
      return 'managed';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected ConfigType enum value');
  }
}

export function protobufFromConfigType(type: M.ConfigType): Config.SchoolYearConfiguration_ConfigType {
  switch (type) {
    case 'managed':
      return Config.SchoolYearConfiguration_ConfigType.MANAGED;
    case 'standalone':
      return Config.SchoolYearConfiguration_ConfigType.STANDALONE;
  }
}

export function integrationFromProtobuf(pb: Config.SchoolYearConfiguration_Integration): M.Integration {
  switch (pb) {
    case Config.SchoolYearConfiguration_Integration.UNDEFINED:
      return 'undefined';
    case Config.SchoolYearConfiguration_Integration.GRADESLAM:
      return 'gradeslam';
    case Config.SchoolYearConfiguration_Integration.GOOGLE_CLASSROOM:
      return 'google-classroom';
    case Config.SchoolYearConfiguration_Integration.VERACROSS:
      return 'veracross';
    case Config.SchoolYearConfiguration_Integration.CANVAS:
      return 'canvas';
    case Config.SchoolYearConfiguration_Integration.ICAL:
      return 'ical';
    case Config.SchoolYearConfiguration_Integration.BLACKBAUD:
      return 'blackbaud';
    case Config.SchoolYearConfiguration_Integration.GOOGLE_CLASSROOM_CONNECTOR:
      return 'google-classroom-connector';
    case Config.SchoolYearConfiguration_Integration.SCHOOLOGY_CONNECTOR:
      return 'schoology-connector';
    case Config.SchoolYearConfiguration_Integration.VERACROSS_CONNECTOR:
      return 'veracross-connector';
    case Config.SchoolYearConfiguration_Integration.VERACROSS_V3_CONNECTOR:
      return 'veracross-v3-connector';
    case Config.SchoolYearConfiguration_Integration.MANAGEBAC_CONNECTOR:
      return 'managebac-connector';
    case Config.SchoolYearConfiguration_Integration.MOODLE_CONNECTOR:
      return 'moodle-connector';
    case Config.SchoolYearConfiguration_Integration.STUDYO_INTERNAL_CONNECTOR:
      return 'studyo-internal';
    case Config.SchoolYearConfiguration_Integration.TEAMS_CONNECTOR:
      return 'microsoft-teams-connector';
    case Config.SchoolYearConfiguration_Integration.BLACKBAUD_SKY_CONNECTOR:
      return 'blackbaud-sky-connector';
    default:
      // The server could expose new values before this app is updated.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.error(`Unknown integration value ${pb}`);
      return 'undefined';
  }
}

export function protobufFromIntegration(integration: M.Integration): Config.SchoolYearConfiguration_Integration {
  switch (integration) {
    case 'undefined':
      return Config.SchoolYearConfiguration_Integration.UNDEFINED;
    case 'gradeslam':
      return Config.SchoolYearConfiguration_Integration.GRADESLAM;
    case 'google-classroom':
      return Config.SchoolYearConfiguration_Integration.GOOGLE_CLASSROOM;
    case 'veracross':
      return Config.SchoolYearConfiguration_Integration.VERACROSS;
    case 'canvas':
      return Config.SchoolYearConfiguration_Integration.CANVAS;
    case 'ical':
      return Config.SchoolYearConfiguration_Integration.ICAL;
    case 'blackbaud':
      return Config.SchoolYearConfiguration_Integration.BLACKBAUD;
    case 'google-classroom-connector':
      return Config.SchoolYearConfiguration_Integration.GOOGLE_CLASSROOM_CONNECTOR;
    case 'schoology-connector':
      return Config.SchoolYearConfiguration_Integration.SCHOOLOGY_CONNECTOR;
    case 'veracross-connector':
      return Config.SchoolYearConfiguration_Integration.VERACROSS_CONNECTOR;
    case 'veracross-v3-connector':
      return Config.SchoolYearConfiguration_Integration.VERACROSS_V3_CONNECTOR;
    case 'managebac-connector':
      return Config.SchoolYearConfiguration_Integration.MANAGEBAC_CONNECTOR;
    case 'moodle-connector':
      return Config.SchoolYearConfiguration_Integration.MOODLE_CONNECTOR;
    case 'studyo-internal':
      return Config.SchoolYearConfiguration_Integration.STUDYO_INTERNAL_CONNECTOR;
    case 'microsoft-teams-connector':
      return Config.SchoolYearConfiguration_Integration.TEAMS_CONNECTOR;
    case 'blackbaud-sky-connector':
      return Config.SchoolYearConfiguration_Integration.BLACKBAUD_SKY_CONNECTOR;
  }
}

export function scheduleKindFromProtobuf(pb: Config.SchoolYearConfiguration_ScheduleKind): M.ScheduleKind {
  switch (pb) {
    case Config.SchoolYearConfiguration_ScheduleKind.CYCLE_DAYS:
      return 'cycle-days';
    case Config.SchoolYearConfiguration_ScheduleKind.WEEK:
      return 'week';
    case Config.SchoolYearConfiguration_ScheduleKind.ALTERNATING_WEEKS:
      return 'alternating-weeks';
    case Config.SchoolYearConfiguration_ScheduleKind.CLASS_TIMES:
      return 'class-times';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected ScheduleKind enum value');
  }
}

export function protobufFromScheduleKind(kind: M.ScheduleKind): Config.SchoolYearConfiguration_ScheduleKind {
  switch (kind) {
    case 'cycle-days':
      return Config.SchoolYearConfiguration_ScheduleKind.CYCLE_DAYS;
    case 'week':
      return Config.SchoolYearConfiguration_ScheduleKind.WEEK;
    case 'alternating-weeks':
      return Config.SchoolYearConfiguration_ScheduleKind.ALTERNATING_WEEKS;
    case 'class-times':
      return Config.SchoolYearConfiguration_ScheduleKind.CLASS_TIMES;
  }
}

export function cycleDayKindFromProtobuf(pb: Config.SchoolYearConfiguration_CycleDayKind): M.CycleDayKind {
  switch (pb) {
    case Config.SchoolYearConfiguration_CycleDayKind.NUMBERS:
      return 'numbers';
    case Config.SchoolYearConfiguration_CycleDayKind.LETTERS:
      return 'letters';
    case Config.SchoolYearConfiguration_CycleDayKind.NONE:
      return 'none';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected CycleDayKind enum value');
  }
}

export function protobufFromCycleDayKind(kind: M.CycleDayKind): Config.SchoolYearConfiguration_CycleDayKind {
  switch (kind) {
    case 'numbers':
      return Config.SchoolYearConfiguration_CycleDayKind.NUMBERS;
    case 'letters':
      return Config.SchoolYearConfiguration_CycleDayKind.LETTERS;
    case 'none':
      return Config.SchoolYearConfiguration_CycleDayKind.NONE;
  }
}

export function gradeLevelSourceFromProtobuf(
  source: Config.SchoolYearConfiguration_GradeLevelSource
): M.GradeLevelSource {
  switch (source) {
    case Config.SchoolYearConfiguration_GradeLevelSource.ACCOUNT:
      return 'account';
    case Config.SchoolYearConfiguration_GradeLevelSource.SECTION:
      return 'section';
  }
}

export function protobufFromGradeLevelSource(
  source: M.GradeLevelSource
): Config.SchoolYearConfiguration_GradeLevelSource {
  switch (source) {
    case 'account':
      return Config.SchoolYearConfiguration_GradeLevelSource.ACCOUNT;
    case 'section':
      return Config.SchoolYearConfiguration_GradeLevelSource.SECTION;
  }
}

export function contentIconFromProtobuf(pb: Contents.ContentDefinition_ContentIcon): M.ContentIcon {
  switch (pb) {
    case Contents.ContentDefinition_ContentIcon.HOMEWORK:
      return 'homework';
    case Contents.ContentDefinition_ContentIcon.EXAM:
      return 'exam';
    case Contents.ContentDefinition_ContentIcon.ORAL:
      return 'oral';
    case Contents.ContentDefinition_ContentIcon.READING:
      return 'reading';
    case Contents.ContentDefinition_ContentIcon.LAB:
      return 'lab';
    case Contents.ContentDefinition_ContentIcon.PERSONAL:
      return 'personal';
    case Contents.ContentDefinition_ContentIcon.TEAMWORK:
      return 'teamwork';
    case Contents.ContentDefinition_ContentIcon.ACTIVITY:
      return 'activity';
    case Contents.ContentDefinition_ContentIcon.MESSAGE:
      return 'message';
    case Contents.ContentDefinition_ContentIcon.MINITEST:
      return 'minitest';
    case Contents.ContentDefinition_ContentIcon.PLAY:
      return 'play';
    case Contents.ContentDefinition_ContentIcon.MUSIC:
      return 'music';
    case Contents.ContentDefinition_ContentIcon.REMINDER:
      return 'reminder';
    case Contents.ContentDefinition_ContentIcon.SPORT:
      return 'sport';
    case Contents.ContentDefinition_ContentIcon.STUDY:
      return 'study';
    case Contents.ContentDefinition_ContentIcon.TUTORING:
      return 'tutoring';
    case Contents.ContentDefinition_ContentIcon.PROJECT:
      return 'project';
    case Contents.ContentDefinition_ContentIcon.RECUPERATION:
      return 'recuperation';
    case Contents.ContentDefinition_ContentIcon.DANCE:
      return 'dance';
    case Contents.ContentDefinition_ContentIcon.LIBRARY:
      return 'library';
    case Contents.ContentDefinition_ContentIcon.SIGNATURE:
      return 'signature';
    case Contents.ContentDefinition_ContentIcon.MOVIE:
      return 'movie';
    case Contents.ContentDefinition_ContentIcon.SURVEY:
      return 'survey';
    case Contents.ContentDefinition_ContentIcon.VIDEOCONFERENCE:
      return 'videoconference';
    case Contents.ContentDefinition_ContentIcon.ART:
      return 'art';
    case Contents.ContentDefinition_ContentIcon.WORK_PLAN:
      return 'workplan';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected ContentIcon enum value');
      return 'homework';
  }
}

export function protobufFromContentIcon(icon: M.ContentIcon): Contents.ContentDefinition_ContentIcon {
  switch (icon) {
    case 'homework':
      return Contents.ContentDefinition_ContentIcon.HOMEWORK;
    case 'exam':
      return Contents.ContentDefinition_ContentIcon.EXAM;
    case 'oral':
      return Contents.ContentDefinition_ContentIcon.ORAL;
    case 'reading':
      return Contents.ContentDefinition_ContentIcon.READING;
    case 'lab':
      return Contents.ContentDefinition_ContentIcon.LAB;
    case 'personal':
      return Contents.ContentDefinition_ContentIcon.PERSONAL;
    case 'teamwork':
      return Contents.ContentDefinition_ContentIcon.TEAMWORK;
    case 'activity':
      return Contents.ContentDefinition_ContentIcon.ACTIVITY;
    case 'message':
      return Contents.ContentDefinition_ContentIcon.MESSAGE;
    case 'minitest':
      return Contents.ContentDefinition_ContentIcon.MINITEST;
    case 'play':
      return Contents.ContentDefinition_ContentIcon.PLAY;
    case 'music':
      return Contents.ContentDefinition_ContentIcon.MUSIC;
    case 'reminder':
      return Contents.ContentDefinition_ContentIcon.REMINDER;
    case 'sport':
      return Contents.ContentDefinition_ContentIcon.SPORT;
    case 'study':
      return Contents.ContentDefinition_ContentIcon.STUDY;
    case 'tutoring':
      return Contents.ContentDefinition_ContentIcon.TUTORING;
    case 'project':
      return Contents.ContentDefinition_ContentIcon.PROJECT;
    case 'recuperation':
      return Contents.ContentDefinition_ContentIcon.RECUPERATION;
    case 'dance':
      return Contents.ContentDefinition_ContentIcon.DANCE;
    case 'library':
      return Contents.ContentDefinition_ContentIcon.LIBRARY;
    case 'signature':
      return Contents.ContentDefinition_ContentIcon.SIGNATURE;
    case 'movie':
      return Contents.ContentDefinition_ContentIcon.MOVIE;
    case 'survey':
      return Contents.ContentDefinition_ContentIcon.SURVEY;
    case 'videoconference':
      return Contents.ContentDefinition_ContentIcon.VIDEOCONFERENCE;
    case 'art':
      return Contents.ContentDefinition_ContentIcon.ART;
    case 'workplan':
      return Contents.ContentDefinition_ContentIcon.WORK_PLAN;
  }
}

export function contentStateFromProtobuf(pb: Contents.ContentDefinition_ContentState): M.ContentState {
  switch (pb) {
    case Contents.ContentDefinition_ContentState.ACTIVE:
      return 'active';
    case Contents.ContentDefinition_ContentState.COMPLETED:
      return 'completed';
    case Contents.ContentDefinition_ContentState.CANCELLED:
      return 'cancelled';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected ContentState enum value');
  }
}

export function protobufFromContentState(state: M.ContentState): Contents.ContentDefinition_ContentState {
  switch (state) {
    case 'active':
      return Contents.ContentDefinition_ContentState.ACTIVE;
    case 'cancelled':
      return Contents.ContentDefinition_ContentState.CANCELLED;
    case 'completed':
      return Contents.ContentDefinition_ContentState.COMPLETED;
  }
}

export function publishTargetKindFromProtobuf(pb: Contents.ContentDefinition_PublishTargetKind): M.PublishTargetKind {
  switch (pb) {
    case Contents.ContentDefinition_PublishTargetKind.SECTION:
      return 'section';
    case Contents.ContentDefinition_PublishTargetKind.ACCOUNTS:
      return 'accounts';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected PublishTargetKind enum value');
  }
}

export function protobufFromPublishTargetKind(
  value: M.PublishTargetKind
): Contents.ContentDefinition_PublishTargetKind {
  switch (value) {
    case 'section':
      return Contents.ContentDefinition_PublishTargetKind.SECTION;
    case 'accounts':
      return Contents.ContentDefinition_PublishTargetKind.ACCOUNTS;
  }
}

export function publishStatusFromProtobuf(pb: Contents.ContentDefinition_PublishStatus): M.PublishStatus {
  switch (pb) {
    case Contents.ContentDefinition_PublishStatus.NOT_PUBLISHED:
      return 'not-published';
    case Contents.ContentDefinition_PublishStatus.PUBLISHING:
      return 'publishing';
    case Contents.ContentDefinition_PublishStatus.PUBLISHED:
      return 'published';
    case Contents.ContentDefinition_PublishStatus.PUBLISH_ERROR:
      return 'publish-error';
  }
}

export function contentKindFromProtobuf(pb: Contents.ContentDefinition_ContentKind): M.ContentKind {
  switch (pb) {
    case Contents.ContentDefinition_ContentKind.TASK:
      return 'task';
    case Contents.ContentDefinition_ContentKind.NOTE:
      return 'note';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected ContentKind enum value');
  }
}

export function protobufFromContentKind(kind: M.ContentKind): Contents.ContentDefinition_ContentKind {
  switch (kind) {
    case 'task':
      return Contents.ContentDefinition_ContentKind.TASK;
    case 'note':
      return Contents.ContentDefinition_ContentKind.NOTE;
  }
}

export function attachmentKindFromProtobuf(pb: Contents.ContentDefinition_AttachmentKind): M.AttachmentKind {
  switch (pb) {
    case Contents.ContentDefinition_AttachmentKind.PHOTO:
      return 'photo';
    case Contents.ContentDefinition_AttachmentKind.DOCUMENT:
      return 'document';
    case Contents.ContentDefinition_AttachmentKind.URL:
      return 'url';
    case Contents.ContentDefinition_AttachmentKind.DOCUMENT_URL:
      return 'document-url';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected AttachmentKind enum value');
      return 'document';
  }
}

export function protobufFromAttachmentKind(kind: M.AttachmentKind): Contents.ContentDefinition_AttachmentKind {
  switch (kind) {
    case 'photo':
      return Contents.ContentDefinition_AttachmentKind.PHOTO;
    case 'document':
      return Contents.ContentDefinition_AttachmentKind.DOCUMENT;
    case 'url':
      return Contents.ContentDefinition_AttachmentKind.URL;
    case 'document-url':
      return Contents.ContentDefinition_AttachmentKind.DOCUMENT_URL;
  }
}

export function contentWorkloadLevelFromProtobuf(pb: Contents.ContentDefinition_WorkloadLevel): M.ContentWorkloadLevel {
  switch (pb) {
    case Contents.ContentDefinition_WorkloadLevel.UNKNOWN:
      return 'unknown';
    case Contents.ContentDefinition_WorkloadLevel.NONE:
      return 'none';
    case Contents.ContentDefinition_WorkloadLevel.REGULAR:
      return 'regular';
    case Contents.ContentDefinition_WorkloadLevel.MEDIUM:
      return 'medium';
    case Contents.ContentDefinition_WorkloadLevel.MAJOR:
      return 'major';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected WorkloadLevel enum value');
      return 'unknown';
  }
}

export function protobufFromContentWorkloadLevel(
  level: M.ContentWorkloadLevel
): Contents.ContentDefinition_WorkloadLevel {
  switch (level) {
    case 'none':
      return Contents.ContentDefinition_WorkloadLevel.NONE;
    case 'regular':
      return Contents.ContentDefinition_WorkloadLevel.REGULAR;
    case 'medium':
      return Contents.ContentDefinition_WorkloadLevel.MEDIUM;
    case 'major':
      return Contents.ContentDefinition_WorkloadLevel.MAJOR;
    case 'unknown':
      return Contents.ContentDefinition_WorkloadLevel.UNKNOWN;
  }
}

export function specialDaySymbolFromProtobuf(pb: MainTypes.SpecialDaySymbol): M.SpecialDaySymbol {
  switch (pb) {
    case MainTypes.SpecialDaySymbol.NONE:
      return 'none';
    case MainTypes.SpecialDaySymbol.SQUARE:
      return 'square';
    case MainTypes.SpecialDaySymbol.STAR:
      return 'star';
    case MainTypes.SpecialDaySymbol.UPENDED_STAR:
      return 'upended-star';
    case MainTypes.SpecialDaySymbol.SYMMETRIC_STAR:
      return 'symmetric-star';
    case MainTypes.SpecialDaySymbol.HOLLOW_SQUARE:
      return 'hollow-square';
    case MainTypes.SpecialDaySymbol.SEAL:
      return 'seal';
    case MainTypes.SpecialDaySymbol.OPEN_BRACKET:
      return 'open-bracket';
    case MainTypes.SpecialDaySymbol.CLOSE_BRACKET:
      return 'close-bracket';
    case MainTypes.SpecialDaySymbol.CIRCLE:
      return 'circle';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected SpecialDaySymbol enum value');
      return 'none';
  }
}

export function protobufFromSpecialDaySymbol(symbol: M.SpecialDaySymbol): MainTypes.SpecialDaySymbol {
  switch (symbol) {
    case 'none':
      return MainTypes.SpecialDaySymbol.NONE;
    case 'square':
      return MainTypes.SpecialDaySymbol.SQUARE;
    case 'star':
      return MainTypes.SpecialDaySymbol.STAR;
    case 'upended-star':
      return MainTypes.SpecialDaySymbol.UPENDED_STAR;
    case 'symmetric-star':
      return MainTypes.SpecialDaySymbol.SYMMETRIC_STAR;
    case 'hollow-square':
      return MainTypes.SpecialDaySymbol.HOLLOW_SQUARE;
    case 'seal':
      return MainTypes.SpecialDaySymbol.SEAL;
    case 'open-bracket':
      return MainTypes.SpecialDaySymbol.OPEN_BRACKET;
    case 'close-bracket':
      return MainTypes.SpecialDaySymbol.CLOSE_BRACKET;
    case 'circle':
      return MainTypes.SpecialDaySymbol.CIRCLE;
  }
}

export function periodKindFromProtobuf(pb: MainTypes.PeriodKind): M.PeriodKind {
  switch (pb) {
    case MainTypes.PeriodKind.CLASS:
      return 'class';
    case MainTypes.PeriodKind.BREAK:
      return 'break';
    case MainTypes.PeriodKind.LUNCH:
      return 'lunch';
    case MainTypes.PeriodKind.FREE:
      return 'free';
    case MainTypes.PeriodKind.STUDY:
      return 'study';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected PeriodKind enum value');
  }
}

export function protobufFromPeriodKind(kind: M.PeriodKind): MainTypes.PeriodKind {
  switch (kind) {
    case 'class':
      return MainTypes.PeriodKind.CLASS;
    case 'break':
      return MainTypes.PeriodKind.BREAK;
    case 'lunch':
      return MainTypes.PeriodKind.LUNCH;
    case 'free':
      return MainTypes.PeriodKind.FREE;
    case 'study':
      return MainTypes.PeriodKind.STUDY;
  }
}

export function userRoleFromProtobuf(pb: Auth.UserRole): M.UserRole {
  switch (pb) {
    case Auth.UserRole.NORMAL_USER:
      return 'normal-user';
    case Auth.UserRole.ROOT_ADMIN_USER:
      return 'root-admin-user';
    case Auth.UserRole.ROOT_OBSERVER_USER:
      return 'root-observer-user';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected UserRole enum value');
      return 'normal-user';
  }
}

export function protobufFromUserRole(role: M.UserRole): Auth.UserRole {
  switch (role) {
    case 'normal-user':
      return Auth.UserRole.NORMAL_USER;
    case 'root-admin-user':
      return Auth.UserRole.ROOT_ADMIN_USER;
    case 'root-observer-user':
      return Auth.UserRole.ROOT_OBSERVER_USER;
  }
}

export function loginKindFromProtobuf(pb: Auth.LoginKind): M.LoginKind {
  switch (pb) {
    case Auth.LoginKind.PASSWORD:
      return 'password';
    case Auth.LoginKind.GOOGLE:
      return 'google';
    case Auth.LoginKind.MICROSOFT:
      return 'microsoft';
    case Auth.LoginKind.APPLE:
      return 'apple';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected LoginKind enum value');
      return 'password';
  }
}

export function colorFromProtobuf(pb: MainTypes.Color): M.Color {
  switch (pb) {
    case MainTypes.Color.ORANGE:
      return 'orange';
    case MainTypes.Color.ROYAL_BLUE:
      return 'royal-blue';
    case MainTypes.Color.MAUVE:
      return 'mauve';
    case MainTypes.Color.VIOLET:
      return 'violet';
    case MainTypes.Color.BRIGHT_GREEN:
      return 'bright-green';
    case MainTypes.Color.LIME_GREEN:
      return 'lime-green';
    case MainTypes.Color.LEAF_GREEN:
      return 'leaf-green';
    case MainTypes.Color.TERRACOTTA:
      return 'terracotta';
    case MainTypes.Color.OLIVE:
      return 'olive';
    case MainTypes.Color.BRIGHT_RED:
      return 'bright-red';
    case MainTypes.Color.PALE_BLUE:
      return 'pale-blue';
    case MainTypes.Color.KAKI:
      return 'kaki';
    case MainTypes.Color.DARK_RED:
      return 'dark-red';
    case MainTypes.Color.TEAL:
      return 'teal';
    case MainTypes.Color.BRIGHT_BLUE:
      return 'bright-blue';
    case MainTypes.Color.BRIGHT_PINK:
      return 'bright-pink';
    case MainTypes.Color.PALE_PINK:
      return 'pale-pink';
    case MainTypes.Color.LAVENDER:
      return 'lavender';
    case MainTypes.Color.PEACH:
      return 'peach';
    case MainTypes.Color.DARK_BLUE:
      return 'dark-blue';
    case MainTypes.Color.LIGHT_BMGRAY:
      return 'light-bmgray';
    case MainTypes.Color.MEDIUM_BMGRAY:
      return 'medium-bmgray';
    case MainTypes.Color.DARKER_BMGRAY:
      return 'darker-bmgray';
    case MainTypes.Color.DARKEST_BMGRAY:
      return 'darkest-bmgray';
    case MainTypes.Color.YELLOW:
      return 'yellow';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected Color enum value');
      return 'yellow'; // The last, deliberate
  }
}

export function protobufFromColor(color: M.Color): MainTypes.Color {
  switch (color) {
    case 'orange':
      return MainTypes.Color.ORANGE;
    case 'royal-blue':
      return MainTypes.Color.ROYAL_BLUE;
    case 'mauve':
      return MainTypes.Color.MAUVE;
    case 'violet':
      return MainTypes.Color.VIOLET;
    case 'bright-green':
      return MainTypes.Color.BRIGHT_GREEN;
    case 'lime-green':
      return MainTypes.Color.LIME_GREEN;
    case 'leaf-green':
      return MainTypes.Color.LEAF_GREEN;
    case 'terracotta':
      return MainTypes.Color.TERRACOTTA;
    case 'olive':
      return MainTypes.Color.OLIVE;
    case 'bright-red':
      return MainTypes.Color.BRIGHT_RED;
    case 'pale-blue':
      return MainTypes.Color.PALE_BLUE;
    case 'kaki':
      return MainTypes.Color.KAKI;
    case 'dark-red':
      return MainTypes.Color.DARK_RED;
    case 'teal':
      return MainTypes.Color.TEAL;
    case 'bright-blue':
      return MainTypes.Color.BRIGHT_BLUE;
    case 'bright-pink':
      return MainTypes.Color.BRIGHT_PINK;
    case 'pale-pink':
      return MainTypes.Color.PALE_PINK;
    case 'lavender':
      return MainTypes.Color.LAVENDER;
    case 'peach':
      return MainTypes.Color.PEACH;
    case 'dark-blue':
      return MainTypes.Color.DARK_BLUE;
    case 'light-bmgray':
      return MainTypes.Color.LIGHT_BMGRAY;
    case 'medium-bmgray':
      return MainTypes.Color.MEDIUM_BMGRAY;
    case 'darker-bmgray':
      return MainTypes.Color.DARKER_BMGRAY;
    case 'darkest-bmgray':
      return MainTypes.Color.DARKEST_BMGRAY;
    case 'yellow':
      return MainTypes.Color.YELLOW;
  }
}

export function roleFromProtobuf(pb: MainTypes.Role): M.Role {
  switch (pb) {
    case MainTypes.Role.INDIVIDUAL:
      return 'individual';
    case MainTypes.Role.STUDENT:
      return 'student';
    case MainTypes.Role.TEACHER:
      return 'teacher';
    case MainTypes.Role.PARENT:
      return 'parent';
    case MainTypes.Role.SCHOOL_STAFF:
      return 'school-staff';
    case MainTypes.Role.STUDYO_STAFF:
      return 'studyo-staff';
    default:
      // Because the server could expose new values before this app is updated, we must
      // be able to report unknown values and act upon upstream.
      return 'unknown';
  }
}

export function protobufFromRole(role: M.Role): MainTypes.Role {
  switch (role) {
    case 'individual':
      return MainTypes.Role.INDIVIDUAL;
    case 'student':
      return MainTypes.Role.STUDENT;
    case 'teacher':
      return MainTypes.Role.TEACHER;
    case 'parent':
      return MainTypes.Role.PARENT;
    case 'school-staff':
      return MainTypes.Role.SCHOOL_STAFF;
    case 'studyo-staff':
      return MainTypes.Role.STUDYO_STAFF;
    case 'unknown':
      throw new Error('Cannot process unknown account roles.');
  }
}

export function authorizationRoleFromRole(role: M.Role): AuthorizationRole {
  switch (role) {
    case 'individual':
      return 'individual';
    case 'student':
      return 'student';
    case 'teacher':
      return 'teacher';
    case 'parent':
      return 'parent';
    case 'school-staff':
      return 'school-staff';
    case 'studyo-staff':
      return 'studyo-staff';
    case 'unknown':
      throw new Error('Cannot process unknown account roles.');
  }
}

export function dayOfWeekFromProtobuf(pb: MainTypes.DayOfWeek): M.DayOfWeek {
  switch (pb) {
    case MainTypes.DayOfWeek.SUNDAY:
      return 'sunday';
    case MainTypes.DayOfWeek.MONDAY:
      return 'monday';
    case MainTypes.DayOfWeek.TUESDAY:
      return 'tuesday';
    case MainTypes.DayOfWeek.WEDNESDAY:
      return 'wednesday';
    case MainTypes.DayOfWeek.THURSDAY:
      return 'thursday';
    case MainTypes.DayOfWeek.FRIDAY:
      return 'friday';
    case MainTypes.DayOfWeek.SATURDAY:
      return 'saturday';
    default:
      throw new Error('Unexpected DayOfWeek enum value');
  }
}

export function protobufFromDayOfWeek(dayOfWeek: M.DayOfWeek): MainTypes.DayOfWeek {
  switch (dayOfWeek) {
    case 'sunday':
      return MainTypes.DayOfWeek.SUNDAY;
    case 'monday':
      return MainTypes.DayOfWeek.MONDAY;
    case 'tuesday':
      return MainTypes.DayOfWeek.TUESDAY;
    case 'wednesday':
      return MainTypes.DayOfWeek.WEDNESDAY;
    case 'thursday':
      return MainTypes.DayOfWeek.THURSDAY;
    case 'friday':
      return MainTypes.DayOfWeek.FRIDAY;
    case 'saturday':
      return MainTypes.DayOfWeek.SATURDAY;
  }
}

export function activeUsersKindFromProtobuf(pb: Metrics.ActiveUsersKind): M.ActiveUsersKind {
  switch (pb) {
    case Metrics.ActiveUsersKind.STUDENTS_ANY:
      return 'students-any';
    case Metrics.ActiveUsersKind.STUDENTS_INTERACTED_TASK:
      return 'students-interacted';
    case Metrics.ActiveUsersKind.TEACHERS_ANY:
      return 'teachers-any';
    case Metrics.ActiveUsersKind.TEACHERS_PUBLISHED:
      return 'teachers-published';
    default:
      // This is actually never used, it's a request parameter.
      throw new Error('Unexpected ActiveUsersKind enum value');
  }
}

export function protobufFromActiveUsersKind(kind: M.ActiveUsersKind): Metrics.ActiveUsersKind {
  switch (kind) {
    case 'students-any':
      return Metrics.ActiveUsersKind.STUDENTS_ANY;
    case 'students-interacted':
      return Metrics.ActiveUsersKind.STUDENTS_INTERACTED_TASK;
    case 'teachers-any':
      return Metrics.ActiveUsersKind.TEACHERS_ANY;
    case 'teachers-published':
      return Metrics.ActiveUsersKind.TEACHERS_PUBLISHED;
  }
}

export function activeUsersPeriodFromProtobuf(pb: Metrics.ActiveUsersPeriod): M.ActiveUsersPeriod {
  switch (pb) {
    case Metrics.ActiveUsersPeriod.DAILY:
      return 'daily';
    case Metrics.ActiveUsersPeriod.HOURLY:
      return 'hourly';
    case Metrics.ActiveUsersPeriod.MONTHLY:
      return 'monthly';
    case Metrics.ActiveUsersPeriod.SCHOOL_YEAR:
      return 'school-year';
    case Metrics.ActiveUsersPeriod.WEEKLY:
      return 'weekly';
    default:
      // This is actually never used, it's a request parameter.
      throw new Error('Unexpected ActiveUsersPeriod enum value');
  }
}

export function protobufFromActiveUsersPeriod(period: M.ActiveUsersPeriod): Metrics.ActiveUsersPeriod {
  switch (period) {
    case 'daily':
      return Metrics.ActiveUsersPeriod.DAILY;
    case 'hourly':
      return Metrics.ActiveUsersPeriod.HOURLY;
    case 'monthly':
      return Metrics.ActiveUsersPeriod.MONTHLY;
    case 'school-year':
      return Metrics.ActiveUsersPeriod.SCHOOL_YEAR;
    case 'weekly':
      return Metrics.ActiveUsersPeriod.WEEKLY;
  }
}

export function accountSessionsPeriodFromProtobuf(pb: Metrics.AccountSessionsPeriod): M.AccountSessionsPeriod {
  switch (pb) {
    case Metrics.AccountSessionsPeriod.PER_DAY:
      return 'per-day';
    case Metrics.AccountSessionsPeriod.PER_HOUR:
      return 'per-hour';
    default:
      // This is actually never used, it's a request parameter.
      throw new Error('Unexpected AccountSessionsPeriod enum value');
  }
}

export function protobufFromAccountSessionsPeriod(period: M.AccountSessionsPeriod): Metrics.AccountSessionsPeriod {
  switch (period) {
    case 'per-day':
      return Metrics.AccountSessionsPeriod.PER_DAY;
    case 'per-hour':
      return Metrics.AccountSessionsPeriod.PER_HOUR;
  }
}

export function importSourceKindFromProtobuf(pb: Importer.ImportSourceKind): M.ImportSourceKind {
  switch (pb) {
    case Importer.ImportSourceKind.OtherSourceKind:
      return 'other';
    case Importer.ImportSourceKind.GRICS:
      return 'grics';
    case Importer.ImportSourceKind.Coba:
      return 'coba';
    case Importer.ImportSourceKind.Plurilogic:
      return 'plurilogic';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected ImportSourceKind enum value');
      return 'other';
  }
}

export function protobufFromImportSourceKind(kind: M.ImportSourceKind): Importer.ImportSourceKind {
  switch (kind) {
    case 'coba':
      return Importer.ImportSourceKind.Coba;
    case 'grics':
      return Importer.ImportSourceKind.GRICS;
    case 'plurilogic':
      return Importer.ImportSourceKind.Plurilogic;
    case 'other':
      return Importer.ImportSourceKind.OtherSourceKind;
  }
}

export function importSourceFileKindFromProtobuf(pb: Importer.FileKind): M.FileKind {
  switch (pb) {
    case Importer.FileKind.OtherFileKind:
      return 'other';
    case Importer.FileKind.Text:
      return 'text';
    case Importer.FileKind.Excel:
      return 'excel';
    case Importer.FileKind.CommaSeparatedValue:
      return 'csv';
    case Importer.FileKind.Xml:
      return 'xml';
    case Importer.FileKind.Pdf:
      return 'pdf';
    case Importer.FileKind.Zip:
      return 'zip';
    case Importer.FileKind.DirectData:
      return 'direct-data';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected FileKind enum value');
      return 'other';
  }
}

export function protobufFromImportSourceFileKind(kind: M.FileKind): Importer.FileKind {
  switch (kind) {
    case 'csv':
      return Importer.FileKind.CommaSeparatedValue;
    case 'excel':
      return Importer.FileKind.Excel;
    case 'pdf':
      return Importer.FileKind.Pdf;
    case 'text':
      return Importer.FileKind.Text;
    case 'xml':
      return Importer.FileKind.Xml;
    case 'zip':
      return Importer.FileKind.Zip;
    case 'direct-data':
      return Importer.FileKind.DirectData;
    case 'other':
      return Importer.FileKind.OtherFileKind;
  }
}

export function importSourceFileEncodingFromProtobuf(pb: Importer.FileEncoding): M.FileEncoding {
  switch (pb) {
    case Importer.FileEncoding.UnknownFileEncoding:
      return 'unknown';
    case Importer.FileEncoding.UTF8:
      return 'utf8';
    case Importer.FileEncoding.ANSI:
      return 'ansi';
    case Importer.FileEncoding.Unicode16:
      return 'unicode16';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected FileEncoding enum value');
  }
}

export function protobufFromImportSourceFileEncoding(encoding: M.FileEncoding): Importer.FileEncoding {
  switch (encoding) {
    case 'ansi':
      return Importer.FileEncoding.ANSI;
    case 'unicode16':
      return Importer.FileEncoding.Unicode16;
    case 'utf8':
      return Importer.FileEncoding.UTF8;
    case 'unknown':
      return Importer.FileEncoding.UnknownFileEncoding;
  }
}

export function incidentAreaFromProtobuf(pb: Importer.IncidentArea): M.IncidentArea {
  switch (pb) {
    case Importer.IncidentArea.General:
      return 'general';
    case Importer.IncidentArea.Parsing:
      return 'parsing';
    case Importer.IncidentArea.Analysing:
      return 'analysing';
    case Importer.IncidentArea.Comparing:
      return 'comparing';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected IncidentArea enum value');
  }
}

export function incidentSeverityFromProtobuf(pb: Importer.IncidentSeverity): M.IncidentSeverity {
  switch (pb) {
    case Importer.IncidentSeverity.Informative:
      return 'informative';
    case Importer.IncidentSeverity.Warning:
      return 'warning';
    case Importer.IncidentSeverity.Error:
      return 'error';
    case Importer.IncidentSeverity.FatalError:
      return 'fatal-error';
  }
}

export function externalAccountKindFromProtobuf(pb: Connector.ExternalAccountKind): M.ExternalAccountKind {
  switch (pb) {
    case Connector.ExternalAccountKind.Blackbaud:
      return 'blackbaud';
    case Connector.ExternalAccountKind.BlackbaudSky:
      return 'blackbaud-sky';
    case Connector.ExternalAccountKind.Calendars:
      return 'calendars';
    case Connector.ExternalAccountKind.Canvas:
      return 'canvas';
    case Connector.ExternalAccountKind.Google:
      return 'google';
    case Connector.ExternalAccountKind.Schoology:
      return 'schoology';
    case Connector.ExternalAccountKind.Veracross:
      return 'veracross';
    case Connector.ExternalAccountKind.VeracrossV3:
      return 'veracross-v3';
    case Connector.ExternalAccountKind.ManageBac:
      return 'managebac';
    case Connector.ExternalAccountKind.Moodle:
      return 'moodle';
    case Connector.ExternalAccountKind.StudyoInternal:
      return 'studyo-internal';
    case Connector.ExternalAccountKind.Teams:
      return 'microsoft-teams';
    case Connector.ExternalAccountKind.Any:
      return 'any';
    case Connector.ExternalAccountKind.Unknown:
      return 'unknown';
    default:
      // The server could expose new values before this app is updated.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.error(`Unexpected ExternalAccountKind enum value ${pb}`);
      return 'unknown';
  }
}

export function protobufFromExternalAccountKind(kind: M.ExternalAccountKind): Connector.ExternalAccountKind {
  switch (kind) {
    case 'blackbaud':
      return Connector.ExternalAccountKind.Blackbaud;
    case 'blackbaud-sky':
      return Connector.ExternalAccountKind.BlackbaudSky;
    case 'calendars':
      return Connector.ExternalAccountKind.Calendars;
    case 'canvas':
      return Connector.ExternalAccountKind.Canvas;
    case 'google':
      return Connector.ExternalAccountKind.Google;
    case 'schoology':
      return Connector.ExternalAccountKind.Schoology;
    case 'veracross':
      return Connector.ExternalAccountKind.Veracross;
    case 'veracross-v3':
      return Connector.ExternalAccountKind.VeracrossV3;
    case 'managebac':
      return Connector.ExternalAccountKind.ManageBac;
    case 'moodle':
      return Connector.ExternalAccountKind.Moodle;
    case 'studyo-internal':
      return Connector.ExternalAccountKind.StudyoInternal;
    case 'microsoft-teams':
      return Connector.ExternalAccountKind.Teams;
    case 'any':
      return Connector.ExternalAccountKind.Any;
    case 'unknown':
      return Connector.ExternalAccountKind.Unknown;
  }
}

export function contentPublishedStateFromProtobuf(pb: MainTypes.ContentPublishedState): M.ContentPublishedState {
  switch (pb) {
    case MainTypes.ContentPublishedState.PUBLISHED_ALL:
      return 'publishedAll';
    case MainTypes.ContentPublishedState.PUBLISHED_MASTER:
      return 'publishedMaster';
    case MainTypes.ContentPublishedState.PUBLISHED_SLAVE:
      return 'publishedSlave';
    case MainTypes.ContentPublishedState.NOT_PUBLISHED:
      return 'notPublished';
    case MainTypes.ContentPublishedState.ALL:
      return 'all';
    default:
      // This is actually never used, it's a request parameter.
      throw new Error('Unexpected ContentPublishedState enum value');
  }
}

export function protobufFromContentPublishedState(state: M.ContentPublishedState): MainTypes.ContentPublishedState {
  switch (state) {
    case 'publishedAll':
      return MainTypes.ContentPublishedState.PUBLISHED_ALL;
    case 'publishedMaster':
      return MainTypes.ContentPublishedState.PUBLISHED_MASTER;
    case 'publishedSlave':
      return MainTypes.ContentPublishedState.PUBLISHED_SLAVE;
    case 'notPublished':
      return MainTypes.ContentPublishedState.NOT_PUBLISHED;
    case 'all':
      return MainTypes.ContentPublishedState.ALL;
  }
}

export function onboardingCodeKindFromProtobuf(pb: MainTypes.OnboardingCodeKind): M.OnboardingCodeKind {
  switch (pb) {
    case MainTypes.OnboardingCodeKind.MANAGED_STUDENT:
      return 'managed-student';
    case MainTypes.OnboardingCodeKind.PARENT_INVITATION:
      return 'parent-invitation';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected OnboardingCodeKind enum value');
  }
}

export function protobufFromMoveCourseOccurrencesDirection(direction: M.MoveCourseOccurrencesDirection) {
  switch (direction) {
    case 'left':
      return MoveCourseOccurrencesRequest_MoveDirection.LEFT;
    case 'right':
      return MoveCourseOccurrencesRequest_MoveDirection.RIGHT;
    case 'right-skip':
      return MoveCourseOccurrencesRequest_MoveDirection.RIGHT_SKIP;
  }
}

// This is not really an enum, but fits well here. No need for the opposite
// as the Timestamp type exposes a toDate.
export function protobufFromDate(date: Date): Timestamp {
  const pb = new Timestamp();
  // Make sure not to set fractions of seconds. setSeconds doesn't complain when providing a fraction, but
  // the transport will fail to serialize it.
  pb.seconds = BigInt(Math.floor(date.getTime() / 1000));
  return pb;
}

export function premiumFeatureFromProtobuf(pb: Config.SchoolYearConfiguration_PremiumFeature): M.PremiumFeature {
  switch (pb) {
    case Config.SchoolYearConfiguration_PremiumFeature.ADMIN_CONSOLE:
      return 'admin-console';
    case Config.SchoolYearConfiguration_PremiumFeature.ALL_PUBLISHED_TASKS_WIDGET:
      return 'all-published-tasks-widget';
    case Config.SchoolYearConfiguration_PremiumFeature.ORGANIZATIONAL_QUOTIENT:
      return 'organizational-quotient';
    case Config.SchoolYearConfiguration_PremiumFeature.STUDENT_ENTERED_TASKS_WIDGET:
      return 'student-entered-tasks-widget';
    case Config.SchoolYearConfiguration_PremiumFeature.WORKLOAD_MANAGER_WIDGET:
      return 'workload-manager-widget';
    case Config.SchoolYearConfiguration_PremiumFeature.PARENT_ACCESS:
      return 'parent-access';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected PremiumFeature enum value');
      return 'admin-console';
  }
}

export function protobufFromPremiumFeature(
  premiumFeature: M.PremiumFeature
): Config.SchoolYearConfiguration_PremiumFeature {
  switch (premiumFeature) {
    case 'admin-console':
      return Config.SchoolYearConfiguration_PremiumFeature.ADMIN_CONSOLE;
    case 'organizational-quotient':
      return Config.SchoolYearConfiguration_PremiumFeature.ORGANIZATIONAL_QUOTIENT;
    case 'all-published-tasks-widget':
      return Config.SchoolYearConfiguration_PremiumFeature.ALL_PUBLISHED_TASKS_WIDGET;
    case 'student-entered-tasks-widget':
      return Config.SchoolYearConfiguration_PremiumFeature.STUDENT_ENTERED_TASKS_WIDGET;
    case 'workload-manager-widget':
      return Config.SchoolYearConfiguration_PremiumFeature.WORKLOAD_MANAGER_WIDGET;
    case 'parent-access':
      return Config.SchoolYearConfiguration_PremiumFeature.PARENT_ACCESS;
  }
}

export function canvasGradingConditionFromProtobuf(pb: Canvas.GradingCondition): M.CanvasGradingCondition {
  switch (pb) {
    case Canvas.GradingCondition.ANY:
      return 'any';
    case Canvas.GradingCondition.GRADED:
      return 'graded';
    case Canvas.GradingCondition.PRACTICE:
      return 'practice';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected Canvas GradingCondition enum value');
  }
}

export function protobufFromCanvasGradingCondition(condition: M.CanvasGradingCondition): Canvas.GradingCondition {
  switch (condition) {
    case 'any':
      return Canvas.GradingCondition.ANY;
    case 'graded':
      return Canvas.GradingCondition.GRADED;
    case 'practice':
      return Canvas.GradingCondition.PRACTICE;
  }
}

export function classroomGradingConditionFromProtobuf(pb: Classroom.GradingCondition): M.ClassroomGradingCondition {
  switch (pb) {
    case Classroom.GradingCondition.ANY:
      return 'any';
    case Classroom.GradingCondition.GRADED:
      return 'graded';
    case Classroom.GradingCondition.NOT_GRADED:
      return 'not-graded';
    default:
      // No other possible value in the near future.
      throw new Error('Unexpected Classroom GradingCondition enum value');
  }
}

export function protobufFromClassroomGradingCondition(
  condition: M.ClassroomGradingCondition
): Classroom.GradingCondition {
  switch (condition) {
    case 'any':
      return Classroom.GradingCondition.ANY;
    case 'graded':
      return Classroom.GradingCondition.GRADED;
    case 'not-graded':
      return Classroom.GradingCondition.NOT_GRADED;
  }
}

export function oqValueFromProtobuf(pb: Metrics.OQValue): M.OQValue {
  switch (pb) {
    case Metrics.OQValue.GREEN:
      return 'green';
    case Metrics.OQValue.YELLOW:
      return 'yellow';
    case Metrics.OQValue.RED:
      return 'red';
    case Metrics.OQValue.NONE:
      return 'none';
    default:
      // The server could expose new values before this app is updated.
      console.error('Unexpected OQValue enum value');
      return 'none';
  }
}

export function protobufFromOQValue(oqValue: M.OQValue): Metrics.OQValue {
  switch (oqValue) {
    case 'green':
      return Metrics.OQValue.GREEN;
    case 'yellow':
      return Metrics.OQValue.YELLOW;
    case 'red':
      return Metrics.OQValue.RED;
    case 'none':
      return Metrics.OQValue.NONE;
  }
}

export function onboardingStatusFromProtobuf(pb: Onboarding.Status): M.OnboardingStatus {
  switch (pb) {
    case Onboarding.Status.NOT_STARTED:
      return 'not-started';
    case Onboarding.Status.IN_PROGRESS:
      return 'in-progress';
    case Onboarding.Status.COMPLETED:
      return 'completed';
    case Onboarding.Status.ARCHIVED:
      return 'archived';
  }
}

export function protobufFromOnboardingStatus(status: M.OnboardingStatus): Onboarding.Status {
  switch (status) {
    case 'not-started':
      return Onboarding.Status.NOT_STARTED;
    case 'in-progress':
      return Onboarding.Status.IN_PROGRESS;
    case 'completed':
      return Onboarding.Status.COMPLETED;
    case 'archived':
      return Onboarding.Status.ARCHIVED;
  }
}

export function onboardingTextFormatFromProtobuf(pb: Onboarding.TextFormat): M.OnboardingTextFormat {
  switch (pb) {
    case Onboarding.TextFormat.PAINT_TEXT:
      return 'plain-text';
    case Onboarding.TextFormat.MARKDOWN:
      return 'markdown';
    case Onboarding.TextFormat.HTML:
      return 'html';
  }
}

export function protobufFromOnboardingTextFormat(format: M.OnboardingTextFormat): Onboarding.TextFormat {
  switch (format) {
    case 'plain-text':
      return Onboarding.TextFormat.PAINT_TEXT;
    case 'markdown':
      return Onboarding.TextFormat.MARKDOWN;
    case 'html':
      return Onboarding.TextFormat.HTML;
  }
}

export function onboardingParticipantKindFromProtobuf(pb: Onboarding.ParticipantKind): M.OnboardingParticipantKind {
  switch (pb) {
    case Onboarding.ParticipantKind.STUDYO_ONLY:
      return 'studyo-only';
    case Onboarding.ParticipantKind.CLIENT_ONLY:
      return 'client-only';
    case Onboarding.ParticipantKind.STUDYO_AND_CLIENT:
      return 'studyo-and-client';
  }
}

export function protobufFromOnboardingParticipantKind(kind: M.OnboardingParticipantKind): Onboarding.ParticipantKind {
  switch (kind) {
    case 'studyo-only':
      return Onboarding.ParticipantKind.STUDYO_ONLY;
    case 'client-only':
      return Onboarding.ParticipantKind.CLIENT_ONLY;
    case 'studyo-and-client':
      return Onboarding.ParticipantKind.STUDYO_AND_CLIENT;
  }
}

export function onboardingQuestionKindFromProtobuf(pb: Onboarding.AnswerKind): M.OnboardingQuestionKind {
  switch (pb) {
    case Onboarding.AnswerKind.SIMPLE_TEXT:
      return 'simple-text';
    case Onboarding.AnswerKind.LARGE_TEXT:
      return 'large-text';
    case Onboarding.AnswerKind.SINGLE_CHOICE:
      return 'single-choice';
    case Onboarding.AnswerKind.MULTIPLE_CHOICE:
      return 'multiple-choice';
    case Onboarding.AnswerKind.DATE:
      return 'date';
    case Onboarding.AnswerKind.DATE_TIME:
      return 'date-time';
    case Onboarding.AnswerKind.TIME:
      return 'time';
    case Onboarding.AnswerKind.FILE:
      return 'file';
    case Onboarding.AnswerKind.SENSITIVE_SIMPLE_TEXT:
      return 'sensitive-simple-text';
  }
}

export function protobufFromOnboardingQuestionKind(kind: M.OnboardingQuestionKind): Onboarding.AnswerKind {
  switch (kind) {
    case 'simple-text':
      return Onboarding.AnswerKind.SIMPLE_TEXT;
    case 'large-text':
      return Onboarding.AnswerKind.LARGE_TEXT;
    case 'single-choice':
      return Onboarding.AnswerKind.SINGLE_CHOICE;
    case 'multiple-choice':
      return Onboarding.AnswerKind.MULTIPLE_CHOICE;
    case 'date':
      return Onboarding.AnswerKind.DATE;
    case 'date-time':
      return Onboarding.AnswerKind.DATE_TIME;
    case 'time':
      return Onboarding.AnswerKind.TIME;
    case 'file':
      return Onboarding.AnswerKind.FILE;
    case 'sensitive-simple-text':
      return Onboarding.AnswerKind.SENSITIVE_SIMPLE_TEXT;
  }
}

export function onboardingOperationKindFromProtobuf(pbKind: Onboarding.OperationKind): M.OnboardingOperationKind {
  switch (pbKind) {
    case Onboarding.OperationKind.UNSPECIFIED_OPERATION:
      return 'unspecified';
    case Onboarding.OperationKind.CREATE_OPERATION:
      return 'create';
    case Onboarding.OperationKind.UPDATE_OPERATION:
      return 'update';
    case Onboarding.OperationKind.UPDATE_STATUS_OPERATION:
      return 'update-status';
    case Onboarding.OperationKind.UPDATE_ASSIGNEES_OPERATION:
      return 'update-assignees';
    case Onboarding.OperationKind.UPDATE_ANSWER_OPERATION:
      return 'update-answer';
    case Onboarding.OperationKind.DELETE_OPERATION:
      return 'delete';
    case Onboarding.OperationKind.RENAME_OPERATION:
      return 'rename';
    case Onboarding.OperationKind.UPLOAD_FILE_OPERATION:
      return 'upload-file';
    case Onboarding.OperationKind.UPDATE_TARGET_DATE:
      return 'update-target-date';
    case Onboarding.OperationKind.UPDATE_FORCED_VISIBILITY:
      return 'update-forced-visibility';
  }
}

export function configStateFromProtobuf(pb: Config.SchoolYearConfiguration_ConfigState): M.ConfigState {
  switch (pb) {
    case Config.SchoolYearConfiguration_ConfigState.PREPARING:
      return 'preparing';
    case Config.SchoolYearConfiguration_ConfigState.ACTIVE:
      return 'active';
    case Config.SchoolYearConfiguration_ConfigState.ARCHIVED:
      return 'archived';
    default:
      throw new Error('Unexpected ConfigState enum value');
  }
}

export function protobufFromConfigState(type: M.ConfigState): Config.SchoolYearConfiguration_ConfigState {
  switch (type) {
    case 'preparing':
      return Config.SchoolYearConfiguration_ConfigState.PREPARING;
    case 'active':
      return Config.SchoolYearConfiguration_ConfigState.ACTIVE;
    case 'archived':
      return Config.SchoolYearConfiguration_ConfigState.ARCHIVED;
    default:
      throw new Error('Unexpected ConfigState value');
  }
}

export function commentEffectFromProtobuf(pb: Onboarding.CommentEffect): M.CommentEffect {
  switch (pb) {
    case Onboarding.CommentEffect.NO_EFFECT:
      return 'none';
    case Onboarding.CommentEffect.BLOCKS_STEP_EFFECT:
      return 'blocks-step';
    case Onboarding.CommentEffect.UNBLOCKS_STEP_EFFECT:
      return 'unblocks-step';
    default:
      throw new Error('Unexpected CommentEffect enum value');
  }
}

export function protobufFromCommentEffect(effect: M.CommentEffect): Onboarding.CommentEffect {
  switch (effect) {
    case 'none':
      return Onboarding.CommentEffect.NO_EFFECT;
    case 'blocks-step':
      return Onboarding.CommentEffect.BLOCKS_STEP_EFFECT;
    case 'unblocks-step':
      return Onboarding.CommentEffect.UNBLOCKS_STEP_EFFECT;
    default:
      throw new Error('Unexpected CommentEffect enum value');
  }
}

export function subscriptionEntitlementFromProtobuf(
  entitlement: Subscriptions.SubscriptionEntitlement
): M.SubscriptionEntitlement {
  switch (entitlement) {
    case Subscriptions.SubscriptionEntitlement.PARENT_ACCESS:
      return 'parent-access';
    case Subscriptions.SubscriptionEntitlement.UNKNOWN:
      return 'unknown';
    default:
      throw new Error('Unexpected SubscriptionEntitlement enum value');
  }
}

export function protobufFromSubscriptionEntitlement(
  entitlement: M.SubscriptionEntitlement
): Subscriptions.SubscriptionEntitlement {
  switch (entitlement) {
    case 'parent-access':
      return Subscriptions.SubscriptionEntitlement.PARENT_ACCESS;
    case 'unknown':
      return Subscriptions.SubscriptionEntitlement.UNKNOWN;
    default:
      throw new Error('Unexpected SubscriptionEntitlement');
  }
}

export function entityListKindFromProtobuf(kind: Importer.EntityListKind): M.EntityListKind {
  switch (kind) {
    case Importer.EntityListKind.AddedEntities:
      return 'added-entities';
    case Importer.EntityListKind.UpdatedEntities:
      return 'updated-entities';
    case Importer.EntityListKind.RemovedEntities:
      return 'removed-entities';
    case Importer.EntityListKind.SkippedEntities:
      return 'skipped-entities';
  }
}

export function protobufFromEntityListKind(kind: M.EntityListKind): Importer.EntityListKind {
  switch (kind) {
    case 'added-entities':
      return Importer.EntityListKind.AddedEntities;
    case 'updated-entities':
      return Importer.EntityListKind.UpdatedEntities;
    case 'removed-entities':
      return Importer.EntityListKind.RemovedEntities;
    case 'skipped-entities':
      return Importer.EntityListKind.SkippedEntities;
  }
}
